
import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue';
import DataPointViz from '@/components/DataPointViz.vue';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { WindStation } from '@/model';

@Component({
  components: {
    DataPointViz,
    DataPointListTimeViz
  }
})
export default class DenseWindStationView extends Vue {
  items!: WindStation[];

  @Prop() stationValues!: WindStation[];

  @Watch('stationValues', { immediate: true })
  onStationValuesChanged(newValues: WindStation[]) {
    this.items = newValues;
  }
}
