import './plugins/vue-class-component';
import vuetify from './plugins/vuetify';

import Vue from 'vue';
import i18n from './plugins/i18n';
import App from './views/App/App.vue';
import store, { routeNamespace } from '@/store/index';
import Vuex from 'vuex';
import { createRouter } from '@/router/router';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import Vuetify from 'vuetify';

import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV === 'development';

Vue.use(Vuex);
Vue.use(Router);
Vue.use(Vuetify);

Vue.use(require('vue-filter-pretty-bytes'));

// https://docs.sentry.io/platforms/javascript/vue/
Sentry.init({
  dsn: runtimeAppSettings.sentryDsn,
  integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
  release: process.env.VUE_APP_VERSION, // see .env
  // see https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
  environment: process.env.NODE_ENV
});

const router = createRouter(store);

// sync router with vuex store
sync(store, router, { moduleName: routeNamespace });

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
