import { VuexOidcClientSettings } from 'vuex-oidc';

/**
 * @param oidcAuthority url of the oidc authority
 * @param redirectRoot root url the webapplication is running at
 */
export function createOidcSettings(oidcAuthority: string, redirectRoot: string): VuexOidcClientSettings {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const finalRootUrl = isDevelopment ? `${window.location.protocol}//${window.location.host}` : redirectRoot;

  return {
    authority: oidcAuthority,
    clientId: 'prd-ui',
    redirectUri: finalRootUrl + '/oidc-callback',
    postLogoutRedirectUri: finalRootUrl,
    responseType: 'id_token token',
    scope: 'openid profile',
    uiLocales: 'de',
    accessTokenExpiringNotificationTime: 30,
    silentRedirectUri: finalRootUrl + '/oidc-silent-renew.html',
    automaticSilentRenew: true,
    mergeClaims: true
  } as VuexOidcClientSettings;
}
