
// css needed for proper OL map functionality
import 'ol/ol.css';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GeoJSON } from 'ol/format';
import VectorSource from 'ol/source/Vector';

import { createZermattMap } from './SwissTopoMapFactory';
import { MapContext } from '@/areas/SiteService/components/MapManager/types';
import { IRawGeojsonFeatureCollection } from '@/store/types';

@Component({})
export default class SwissTopoMap extends Vue {
  @Prop(Object) geojson!: IRawGeojsonFeatureCollection;
  @Prop({
    type: Boolean,
    default: false
  })
  fullWidth!: boolean;
  @Prop(Boolean) tall!: boolean;

  @Watch('geojson')
  onGeojsonChanged(newGeojson: IRawGeojsonFeatureCollection) {
    // gets notified once geojson is available
    if (newGeojson && !this.hasMapMounted) {
      this.createMap();
    }
  }

  private mapContext: MapContext = { map: null };
  private hasMapMounted: boolean = false;

  get features() {
    return new GeoJSON().readFeatures(this.geojson);
  }

  async beforeDestroy() {
    // Disconnect map object from DOM. This avoids errors because the map object tries to do stuff on the DOM element
    // that has already been destroyed. This happens when navigating away (e.g. openSite()). See also
    // https://github.com/openlayers/openlayers/issues/11231
    this.mapContext.map?.setTarget(undefined);
  }

  mounted() {
    if (this.geojson && !this.hasMapMounted) {
      this.createMap();
    }
  }

  async createMap() {
    if (!this.$refs.map) {
      return;
    }

    const initialViewExtent = this.getInitialExtent(this.geojson);
    const map = createZermattMap(initialViewExtent);

    map.setTarget(this.$refs.map as any);

    this.mapContext.map = map;

    this.hasMapMounted = true;
  }

  getInitialExtent(geojson: IRawGeojsonFeatureCollection) {
    const extent = new VectorSource({ features: this.features }).getExtent();
    return extent;
  }

  zoom(offset: number) {
    if (this.mapContext.map) {
      const view = this.mapContext.map.getView();
      const currentZoom = view.getZoom();

      if (currentZoom) {
        view.animate({
          zoom: currentZoom + offset,
          duration: 200
        });
      }
    }
  }
}
