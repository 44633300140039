import { Module } from 'vuex';
import { weatherClient } from '@/api/zbag-prd/httpClients';
import { IRootState } from '@/store/types';
import { mapWeatherData } from './utils';
import { IWeatherState } from './types';
import { IWeather } from '@/model';

const weatherModule: Module<IWeatherState, IRootState> = {
  namespaced: true,
  state: {
    weather: { snow: { stationValues: [] }, wind: { stationValues: [] }, temperature: { stationValues: [] } }
  },
  mutations: {
    setWeather(state, weather: IWeather) {
      state.weather = weather;
    }
  },
  actions: {
    async fetchWeather({ commit, rootState }: any, { regionId }: { regionId: string }) {
      const response = await weatherClient.fetchWeather(regionId);
      const result = mapWeatherData(response, rootState.prd.stations);
      commit('setWeather', result);
    }
  }
};

export default weatherModule;
