
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { INavigationItem } from '@/views/App/types';

@Component({
  components: {}
})
export default class DesktopMenu extends Vue {
  @Prop()
  navigationItems!: INavigationItem[];

  @Prop()
  value!: boolean;

  @Emit('input')
  emitDrawer(event: boolean) {
    return event;
  }
}
