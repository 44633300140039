import { ICoordinatesDto } from '@/api/zbag-prd/issues/types';

export enum SiteKind {
  Slope = 'Slope',
  FunSlope = 'FunSlope',
  SnowPark = 'SnowPark',
  SosStation = 'SosStation',
  Fence = 'Fence'
}

export interface ISiteListDto {
  sites: ISiteDto[];
}

export interface IStatisticsDto {
  lastTechnicalInspection: string;
  lastFinalInspection: string;
  openIssuesCount: number;
}

export interface ISiteDto {
  id: string;
  name: string;
  number: string;
  kind: SiteKind;
  regionId: string;
  statistics: IStatisticsDto | null;
}

export interface ISiteDetailDto extends ISiteDto {
  location: ICoordinatesDto;
}

export interface IGetSingleSiteRequestDto {
  regionId: string;
  siteId: string;
}
