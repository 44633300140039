import { GetterTree } from 'vuex';
import { IRawGeojsonFeature, IRawGeojsonFeatureCollection, IRootState } from '@/store/types';
import { User } from '@/model';

const getters: GetterTree<IRootState, IRootState> = {
  currentUser(state) {
    return state.currentUser;
  },
  getUser: state => (userId: string): User | undefined => state.users.find(u => u.id === userId),
  currentRegionId: state => state.route!.params.regionId,
  getRegionById: state => (regionId: string) => state.regions.find(region => region.id === regionId) || null,
  sitesGeojson: (state): IRawGeojsonFeatureCollection | null => {
    return state.sitesGeojson;
  },
  getGeojsonBySiteId: state => (siteId: string): IRawGeojsonFeature | null => {
    if (state.sitesGeojson) {
      return state.sitesGeojson.features.find(feature => feature.properties.id === siteId) || null;
    }

    return null;
  },
  allUsers: state => state.users
};

export default getters;
