
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ISnowSituation, OwnObservations } from '@/model';
import DataPointViz from '@/components/DataPointViz.vue';
import MeasurementTable from '@/components/MeasurementTable.vue';
import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue';
import SnowChart from '@/components/SnowChart.vue';
import DenseSnowStationView from '@/components/DenseSnowStationView.vue';
import { ChartDisplayTimeRange } from '@/model';

@Component({
  components: {
    DenseSnowStationView,
    SnowChart,
    DataPointListTimeViz,
    MeasurementTable,
    DataPointViz
  }
})
export default class SnowSituation extends Vue {
  @Prop()
  public snowSituation!: ISnowSituation;

  @Prop()
  public displayTimeRange!: ChartDisplayTimeRange<Date>;

  @Prop()
  public ownObservations!: OwnObservations;

  @Prop()
  public enableSnowLine!: boolean;

  @Prop()
  public editMode!: boolean;

  public snowLine: string | null = null;

  public snowLineSaved = false;

  public mounted() {
    this.snowLine = this.ownObservations?.snowLine ? this.ownObservations.snowLine.toString() : null;
  }

  public get isDenseScreen(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public get headers() {
    return [
      {
        text: this.$t('dailyAssessment.conditions.snowSituation.station'),
        value: 'stationName',
        align: 'start'
      },
      {
        text: this.$t('dailyAssessment.conditions.snowSituation.snowDepth'),
        value: 'snowDepth.value',
        align: 'end'
      }
    ];
  }

  public validateSnowLine(value: string | null) {
    return this.isValidSnowLine(value) || this.$t('dailyAssessment.conditions.snowSituation.invalidInput');
  }

  public isValidSnowLine(value: string | null) {
    if (value === '' || value === null) {
      return true;
    }

    let pattern = /^\d{0,4}$/g;
    return pattern.test(value);
  }

  public onSnowLineBlur() {
    if (this.isValidSnowLine(this.snowLine)) {
      this.ownObservations.snowLine = this.snowLine ? parseInt(this.snowLine, 10) : null;
      this.snowLineSaved = true;
      this.emitSnowLineUpdate(this.snowLine);
    }
  }

  @Emit('update:snowline')
  public emitSnowLineUpdate(snowLine: string | null): string | null {
    return snowLine;
  }

  get hasSecondaryStations() {
    const secondaryStations = this.snowSituation.stationValues.filter(item => !item.isPrimary);
    return secondaryStations.length > 0;
  }
}
