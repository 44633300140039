
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Assessment, ICourseOfAction, IRequiredMeasures, User } from '@/model';
import { Getter } from 'vuex-class';
import { cloneDeep } from 'lodash-es';
import NextPlannedAssessmentPicker from '@/components/NextPlannedAssessmentPicker.vue';
import RequiredMeasuresSelector from '@/components/RequiredMeasuresSelector.vue';

@Component({
  components: { RequiredMeasuresSelector, NextPlannedAssessmentPicker }
})
export default class CourseOfAction extends Vue {
  public get notes(): string {
    return this.assessment.courseOfAction.notes;
  }

  public set notes(notes: string) {
    this.updateCourseOfAction(courseOfAction => (courseOfAction.notes = notes));
  }

  @Prop()
  public assessment!: Assessment;

  @Prop()
  public editMode!: boolean;

  @Getter('getUser')
  public getUser!: (userId: string) => User;

  public saveNextPlannedAssessment(nextDate: Date | null) {
    this.updateCourseOfAction(courseOfAction => (courseOfAction.nextPlannedAssessment = nextDate));
  }

  public updateRequiredMeasures(requiredMeasures: IRequiredMeasures | null) {
    this.updateCourseOfAction(courseOfAction => (courseOfAction.requiredMeasures = requiredMeasures));
  }

  private updateCourseOfAction(updater: (courseOfAction: ICourseOfAction) => void) {
    let courseOfAction = this.getCourseOfActionCopy();
    updater(courseOfAction);
    this.emitUpdate(courseOfAction);
  }

  @Emit('update:course-of-action')
  private emitUpdate(courseOfAction: ICourseOfAction | null) {
    return courseOfAction;
  }

  private getCourseOfActionCopy(): ICourseOfAction {
    return cloneDeep(this.assessment.courseOfAction) as ICourseOfAction;
  }
}
