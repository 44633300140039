
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as Highcharts from 'highcharts';
import ResizeObserver from 'resize-observer-polyfill';
import { SnowStation, ChartDisplayTimeRange } from '@/model';
import { fromUnixTime } from 'date-fns';
import DataPointViz from '@/components/DataPointViz.vue';
import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue';
import { categoryColors } from '@/components/definitions';
import { format } from '@/plugins/format';

const chartCache = new WeakMap();

@Component({
  components: { DataPointListTimeViz, DataPointViz }
})
export default class SnowChart extends Vue {
  @Prop()
  public snowStations!: SnowStation[];

  @Prop()
  public displayTimeRange?: ChartDisplayTimeRange<Date>;

  private resizeObserver!: ResizeObserver;

  @Watch('displayTimeRange')
  onDisplayTimeRangeChanged() {
    const chart = chartCache.get(this);

    if (chart) {
      chart.update({
        xAxis: {
          min: this.defaultDisplayTimeRange.from,
          max: this.defaultDisplayTimeRange.to
        }
      });
    }
  }

  get defaultDisplayTimeRange(): ChartDisplayTimeRange<number | null> {
    const from = this.displayTimeRange ? this.displayTimeRange.from.valueOf() : null;
    const to = this.displayTimeRange ? this.displayTimeRange.to.valueOf() : null;

    return {
      from,
      to
    };
  }

  /* (Re-)Create chart on changing data points */
  @Watch('snowStations')
  onSnowStations() {
    const chart = chartCache.get(this);
    if (chart?.container == null && this.snowStations.length) {
      this.createChart(this.$refs.chart);
    } else if (chart.container != null) {
      this.cleanup();
      this.createChart(this.$refs.chart);
    }
  }

  /* Create the chart on initial page reload */
  public mounted() {
    this.snowStations.length && this.createChart(this.$refs.chart);
  }

  public destroyed() {
    this.cleanup();
  }

  public stationColor(station: SnowStation) {
    let index = this.snowStations.indexOf(station);

    return index < 0 ? 'transparent' : categoryColors[index];
  }

  private cleanup() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    const chart = chartCache.get(this);
    chart.destroy();
    chartCache.delete(this);
  }

  private createChart(element: any) {
    let series = this.snowStations.map((s, i) => ({
      type: 'line',
      color: categoryColors[i],
      data: s.snowDepth.map(d => [d.timestamp.valueOf(), d.value]).sort(),
      name: s.stationName
    }));

    const chart = Highcharts.chart(
      element,
      {
        title: undefined,
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        chart: {
          height: 250,
          spacingBottom: 0
        },
        credits: undefined,
        plotOptions: {
          line: {
            marker: {
              enabled: false
            }
          }
        },
        tooltip: {
          formatter: function() {
            let time = format(fromUnixTime(this.x / 1000), 'PPp');
            return `<small>${time}</small><br><b>${this.y.toFixed(2)} cm</b>`;
          }
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: 'datetime',
          min: this.defaultDisplayTimeRange.from,
          max: this.defaultDisplayTimeRange.to
        },
        yAxis: {
          title: {
            text: `${this.$t('dailyAssessment.conditions.snowSituation.chartSnowDepth')} (cm)`
          },
          min: 0,
          softMax: 50
        },
        series: series as any
      },
      undefined
    );

    chartCache.set(this, chart);

    if (ResizeObserver && chart && chart.container) {
      this.resizeObserver = new ResizeObserver(() => chart.reflow());
      this.resizeObserver.observe(element);
    }
  }
}
