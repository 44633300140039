import { IInspection, IIssue, ISite, ISiteDetail, ISiteServiceState } from '@/areas/SiteService/store/types';
import { MutationTree } from 'vuex';
import { IDeleteInspectionActionPayload } from '@/areas/SiteService/store/actions';
import { IPartialIssueFilter } from '@/areas/SiteService/components/IssuesList/types';

interface IAddSitesMutation {
  sites: ISite[];
}

interface IAddSiteDetailMutation {
  site: ISite;
  inspections: IInspection[];
  issues: IIssue[];
}

interface IDeleteInspectionMutation extends IDeleteInspectionActionPayload {}

interface IAddInspectionsOverviewMutation {
  inspections: IInspection[];
}

interface IAddInspectionDetailMutation {
  inspection: IInspection;
  site: ISiteDetail;
}

interface IAddNewInspectionMutation {
  inspection: IInspection;
}

interface IAddIssuesMutation {
  regionId: string;
  issues: IIssue[];
}

interface IPendingUploadMutation {
  stateKey: 'issuesMediaUploading' | 'issuesAttachmentsUploading';
  name: string;
}

interface IAddIssueDetailMutation {
  issue: IIssue;
  site: ISite;
}

interface IUpdateIssueDetailMutation {
  issue: IIssue;
}

interface IAddIssuesToSiteDetailMutation {
  issues: IIssue[];
}

interface IAddInspectionsForDateMutation {
  inspections: IInspection[];
}

const mutations: MutationTree<ISiteServiceState> = {
  addSitesForRegion(state, { sites }: IAddSitesMutation): void {
    state.sites = sites;
  },
  addSiteDetail(state, { site, inspections }: IAddSiteDetailMutation): void {
    state.siteDetail = {
      ...site,
      issues: state.siteDetail?.issues || [],
      inspections,
      issueFilter: null
    };
  },
  addIssuesToSiteDetail(state, { issues }: IAddIssuesToSiteDetailMutation): void {
    if (state.siteDetail) {
      state.siteDetail.issues = issues;
    } else {
      // @ts-ignore
      state.siteDetail = {
        issues,
        inspections: [],
        issueFilter: null
      };
    }
  },
  removeInspection(state, { createdAt, kind, siteId }: IDeleteInspectionMutation): void {
    const indexOfItemToRemove = state.overview.inspections.findIndex(inspection => {
      return (
        siteId == inspection.siteId &&
        createdAt == inspection.createdAt.toISOString() &&
        kind == inspection.kind.toLowerCase()
      );
    });
    if (indexOfItemToRemove > -1) {
      state.overview.inspections.splice(indexOfItemToRemove, 1);
    }
  },
  addInspectionsOverview(state, { inspections }: IAddInspectionsOverviewMutation): void {
    state.overview.inspections = [...inspections];
  },
  addInspectionDetail(state, { inspection, site }: IAddInspectionDetailMutation): void {
    state.inspectionDetail = {
      ...inspection,
      site
    };
  },
  addNewInspection(state, { inspection }: IAddNewInspectionMutation): void {
    state.overview.inspections = [inspection, ...state.overview.inspections];
  },
  addIssuesForRegion(state, { issues }: IAddIssuesMutation): void {
    state.overview.issues = issues;
  },
  addIssueDetail(state, { issue, site }: IAddIssueDetailMutation): void {
    state.issueDetail = {
      ...issue,
      site
    };
  },
  addPendingUpload(state: ISiteServiceState, { stateKey, name }: IPendingUploadMutation) {
    state[stateKey].push(name);
  },
  removePendingUpload(state: ISiteServiceState, { stateKey, name }: IPendingUploadMutation) {
    const index = state[stateKey].indexOf(name);

    if (index > -1) {
      state[stateKey].splice(index, 1);
    }
  },
  updateIssueDetail(state: ISiteServiceState, { issue }: IUpdateIssueDetailMutation): void {
    const cachedSite = state.issueDetail!.site;

    state.issueDetail = {
      ...issue,
      site: cachedSite
    };
  },
  removeIssueDetail(state: ISiteServiceState): void {
    state.issueDetail = null;
  },
  applyIssueFilter(state: ISiteServiceState, { status, createdBy, sort }: IPartialIssueFilter): void {
    state.issueFilter = {
      status,
      createdBy,
      sort
    };
  },
  addInspectionsForDate(state: ISiteServiceState, { inspections }: IAddInspectionsForDateMutation): void {
    state.inspectionsForSelectedDate = inspections;
  },
  setDateForHistoricalInspections(state: ISiteServiceState, date: string): void {
    state.selectedDateForHistoricalInspections = date;
  }
};

export default mutations;
