import { Assessment, IAssessmentSummary, IAttachment, IStation, User } from '@/model';
import { parseISO } from 'date-fns';
import BaseApiClient from '@/api/utils/BaseApiClient';
import { IRegion } from '@/store/types';

export default class AssessmentsApiClient extends BaseApiClient {
  public async fetchStations(): Promise<IStation[]> {
    const response = await this.fetch(`${this.baseUrl}/stations`, this.requestOptions('GET'));

    return response.stations;
  }

  /**
   * Fetches a list of assessments for a given region from the API. Only partial assessment data is returned.
   */
  public async fetchAssessments(regionId: string, from: Date, to: Date): Promise<IAssessmentSummary[]> {
    const params = new URLSearchParams();

    params.set('from', from.toISOString());
    params.set('to', to.toISOString());

    const response = await this.fetch(
      `${this.baseUrl}/regions/${regionId}/assessments?${params.toString()}`,
      this.requestOptions('GET')
    );

    return response.assessments.map((dto: any) => this.reconstituteAssessmentSummary(dto));
  }

  /**
   * Fetches a fully populated assessment from the API.
   */
  public async fetchAssessment(region: IRegion, createdAt: Date): Promise<any> {
    const response = await this.fetch(
      `${this.baseUrl}/regions/${region.id}/assessments/${createdAt.toISOString()}`,
      this.requestOptions('GET')
    );
    return response;
  }

  public async createAssessment(region: IRegion): Promise<any> {
    const response = await this.fetch(`${this.baseUrl}/regions/${region.id}/assessments`, this.requestOptions('POST'));

    const createdAt = parseISO(response.createdAt);

    return await this.fetchAssessment(region, createdAt);
  }

  public async updateAssessment(assessment: Assessment): Promise<any> {
    const options = this.requestOptions('PUT', {
      body: JSON.stringify({
        ownObservations: assessment.currentSituation.ownObservations,
        courseOfAction: assessment.courseOfAction
      }),
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    await this.fetch(
      `${this.baseUrl}/regions/${assessment.region}/assessments/${assessment.createdAt.toISOString()}`,
      options
    );
  }

  public async uploadAttachment(regionId: string, createdAt: Date, file: File): Promise<IAttachment> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const options = this.requestOptions('POST', {
      body: formData,
      headers: {
        Authorization: `Bearer ${this.accessToken}`
        // Intentionally do not define 'Content-Type' header, as the browser will correctly infer it including the boundary parameter
      }
    });
    const result = await this.fetch(
      `${this.baseUrl}/regions/${regionId}/assessments/${createdAt.toISOString()}/attachments`,
      options
    );

    return result;
  }

  public async deleteAttachment(regionId: string, createdAt: Date, attachmentId: string) {
    const options = this.requestOptions('DELETE');
    await this.fetch(
      `${this.baseUrl}/regions/${regionId}/assessments/${createdAt.toISOString()}/attachments/${attachmentId}`,
      options
    );
  }

  public async finalizeAssessment(regionId: string, createdAt: Date): Promise<void> {
    const options = this.requestOptions('POST');
    await this.fetch(`${this.baseUrl}/regions/${regionId}/assessments/${createdAt.toISOString()}/finalize`, options);
  }

  public async deleteAssessment(regionId: string, createdAt: Date): Promise<void> {
    const options = this.requestOptions('DELETE');
    await this.fetch(`${this.baseUrl}/regions/${regionId}/assessments/${createdAt.toISOString()}`, options);
  }

  private reconstituteAssessmentSummary(dto: any): IAssessmentSummary {
    return {
      createdAt: parseISO(dto.createdAt),
      createdBy: dto.createdBy,
      finalizedAt: dto.finalizedAt ? parseISO(dto.finalizedAt) : null,
      finalizedBy: dto.finalizedBy,
      region: dto.region
    };
  }
}
