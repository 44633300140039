
import { Component, Vue, Prop } from 'vue-property-decorator';
import { INavigationItem } from '@/views/App/types';

@Component({
  components: {}
})
export default class MobileMenu extends Vue {
  @Prop()
  navigationItems!: INavigationItem[];
}
