import { first } from 'lodash-es';

export enum Role {
  PrdSupervisor = 'PrdSupervisor',
  PrdViewer = 'PrdViewer',
  PrdPatroller = 'PrdPatroller',
  PrdDispatcher = 'PrdDispatcher',
  PrdSnowcatDriver = 'PrdSnowcatDriver',
  SbsExportManager = 'SbsExportManager'
}

export interface IStation {
  id: string;
  name: string;
}

export interface IAssessmentSummary {
  createdAt: Date;
  createdBy: string;
  finalizedAt: Date | null;
  finalizedBy: string | null;
  region: string;
}

export class Assessment implements IAssessmentSummary {
  constructor(
    public readonly region: string,
    public readonly createdAt: Date,
    public readonly createdBy: string,
    public finalizedBy: string,
    public finalizedAt: Date | null,
    public currentSituation: ICurrentSituation,
    public courseOfAction: ICourseOfAction,
    public changeLog: IChangelog[]
  ) {}
}

export interface IWeather {
  snow: ISnowSituation;
  wind: IWindSituation;
  temperature: ITemperatureSituation;
}

export interface ICurrentSituation extends IWeather {
  ownObservations: OwnObservations;
  attachments: IAttachment[];
  weatherForecast: IWeatherForecast;
  slfEarlyWarning: string;
  avalancheBulletin: IAvalancheBulletin;
}

export type IRegionWeather = {
  region: string;
} & IWeather;

export interface IAvalancheBulletin {
  bulletin: IFileResource | null;
  iconMap: IFileResource | null;
}

export interface IChangelog {
  date: Date;
  changes: IChangelogItem[];
}

export interface IChangelogItem {
  timestamp: Date;
  changeBy: string;
  property: string;
}

export interface IWeatherForecast {
  meteoMediaResources: IFileResource[];
}

export interface IFileResource {
  timestamp: Date;
  url: string;
}

export interface ISnowSituation {
  stationValues: SnowStation[];
}

export interface IAttachment {
  id: string;
  fileName: string;
  fileSizeBytes: number;
  url: string;
  mimeType: string;
}

export class SnowStation {
  constructor(
    public readonly stationName: string,
    public readonly stationId: string,
    public readonly isPrimary: boolean,
    public readonly freshSnow24h: IDataPoint[],
    public readonly freshSnow72h: IDataPoint[],
    public readonly snowDepth: IDataPoint[]
  ) {}

  public get currentFreshSnow24h() {
    return first(this.freshSnow24h);
  }
  public get currentFreshSnow72h() {
    return first(this.freshSnow72h);
  }
  public get currentSnowDepth() {
    return first(this.snowDepth);
  }
}

export interface IWindSituation {
  stationValues: WindStation[];
}

export class WindStation {
  constructor(
    public readonly stationName: string,
    public readonly stationId: string,
    public readonly isPrimary: boolean,
    public readonly average3h: IDataPoint[],
    public readonly average: IDataPoint[],
    public readonly peak: IDataPoint[],
    public readonly direction: IDataPoint[]
  ) {}

  public get currentAverage3h() {
    return first(this.average3h);
  }
  public get currentAverage() {
    return first(this.average);
  }
  public get currentPeak() {
    return first(this.peak);
  }
  public get currentDirection() {
    return first(this.direction);
  }
}

export interface ITemperatureSituation {
  stationValues: TemperatureStation[];
}

export class TemperatureStation {
  constructor(
    public readonly stationName: string,
    public readonly stationId: string,
    public readonly isPrimary: boolean,
    public readonly airTemperature: IDataPoint[],
    public readonly airHumidity: IDataPoint[],
    public readonly snowSurfaceTemperature: IDataPoint[],
    public readonly snowPackTemperature: IDataPoint[]
  ) {}

  public get currentAirTemperature() {
    return first(this.airTemperature);
  }
  public get currentAirHumidity() {
    return first(this.airHumidity);
  }
  public get currentSnowSurfaceTemperature() {
    return first(this.snowSurfaceTemperature);
  }
  public get currentSnowPackTemperature() {
    return first(this.snowPackTemperature);
  }
}

export interface IDataPoint {
  timestamp: Date;
  value: number;
}

export interface IAvalancheProblems {
  altitude: number;
  problems: string[];
  expositions: string[];
}

export interface ICustomAvalancheBulletin {
  estimatedDanger: string;
  avalancheProblemsAbove: IAvalancheProblems | null;
  avalancheProblemsBelow: IAvalancheProblems | null;
  expectedDevelopment: string;
}

export class OwnObservations {
  constructor(
    public snowLine: number | null,
    public weatherObservations: string[],
    public cloudObservation: string | null,
    public windStrengthObservation: string | null,
    public windDirectionObservation: number | null,
    public snowPackObservations: string[],
    public avalancheActivityObservations: string[],
    public avalancheProblemObservations: string[],
    public notes: string,
    public weatherDevelopment: string,
    public customAvalancheBulletin: ICustomAvalancheBulletin | null
  ) {}

  public get windDirectionAbbreviation() {
    if (this.windDirectionObservation === null) {
      return '';
    }

    const abbreviations = ['N', 'NO', 'O', 'SO', 'S', 'SW', 'W', 'NW'];
    const numberOfSlices = abbreviations.length;
    const interval = 360 / numberOfSlices;

    const index = Math.floor(((this.windDirectionObservation % 360) + interval / 2) / interval) % numberOfSlices;
    return abbreviations[index];
  }
}

export interface ICourseOfAction {
  notes: string;
  nextPlannedAssessment: Date | null;
  requiredMeasures: IRequiredMeasures | null;
  availableActionPackages: IActionPackage[];
}

export interface IRequiredMeasures {
  affectedPartOfRegion: string;
  deployWarningSigns: boolean;
  actionPackages: IActionPackage[];
}

export interface IActionPackage {
  id: string;
  blastingMethods: string[];
}

export class User {
  constructor(public readonly id: string, public readonly firstName: string, public readonly lastName: string) {}

  public get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  public get initials() {
    return this.fullName
      .split(' ')
      .map(s => s[0])
      .join('');
  }
}

export interface ChartDisplayTimeRange<T extends Date | null | number> {
  from: T;
  to: T;
}

export enum Regions {
  Sued = 'sued',
  SuedSummer = 'sued_sommer',
  Rothorn = 'rothorn',
  Gornergrat = 'Gornergrat',
  Winter = 'winter' // used to group winter sites for Module 3
}
