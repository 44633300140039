
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChartDisplayTimeRange, TemperatureStation, WindStation } from '@/model';
import WindChart from '@/components/WindChart.vue';
import TemperatureChart from '@/components/TemperatureChart.vue';
import ShowMoreButton from '@/components/ShowMoreButton.vue';

type ChartableStation = TemperatureStation | WindStation;

@Component({
  components: {
    TemperatureChart,
    WindChart,
    ShowMoreButton
  }
})
export default class ExpandableCharts extends Vue {
  @Prop()
  public displayTimeRange!: ChartDisplayTimeRange<Date>;

  @Prop()
  public stationValues!: ChartableStation[];

  get filteredStations() {
    return this.collapsed ? this.stationValues.filter(station => !!station.isPrimary) : this.stationValues;
  }

  get showExpand() {
    return this.stationValues.length && this.stationValues.filter(station => !station.isPrimary).length;
  }

  getChartComponent(station: ChartableStation) {
    switch (true) {
      case station instanceof TemperatureStation:
        return TemperatureChart;
      case station instanceof WindStation:
        return WindChart;
      default:
        throw 'Chart type not supported';
    }
  }

  public collapsed = true;
}
