import { ISite } from '@/areas/SiteService/store/types';
import { IAttachment } from '@/model';
import {
  IGetIncidentsRequestDto,
  IIncidentDetailDto,
  IIncidentExcerptDto,
  IInjuryDto,
  IInvolvementDto,
  IPersonalDataDto,
  IRescuerDto
} from '@/api/zbag-prd/incidents/types';

export interface IPersonalData extends IPersonalDataDto {}
export interface IInvolvement extends IInvolvementDto {}
export interface IInjury extends IInjuryDto {}

export interface IRescuer extends Omit<IRescuerDto, 'assignedAt' | 'arrivedAt'> {
  assignedAt: Date;
  arrivedAt: Date | null;
}

export interface IIncidentExcerpt
  extends Omit<
    IIncidentExcerptDto,
    | 'createdAt'
    | 'alarmedAt'
    | 'resolvedAt'
    | 'signedAt'
    | 'rescuers'
    | 'incidentNumber'
    | 'sbsExportAt'
    | 'excludeFromSbsExport'
    | 'exportStatus'
  > {
  /**
   * Alias for incidentNumber
   */
  incidentId: number;
  alarmedAt: Date;
  resolvedAt: Date | null;
  signedAt: Date | null;
  sbsExportAt: Date | null;
  excludeFromSbsExport: Boolean;
  exportStatus: ExportStatus;
  rescuers: IRescuer[];
}

export interface IIncidentDetail
  extends IIncidentExcerpt,
    Omit<
      IIncidentDetailDto,
      | 'createdAt'
      | 'alarmedAt'
      | 'resolvedAt'
      | 'signedAt'
      | 'rescuers'
      | 'involvedPeople'
      | 'handOverTime'
      | 'handOverOrderTime'
      | 'sbsExportAt'
      | 'excludeFromSbsExport'
    > {
  patient: IPersonalData;
  attachments: IAttachment[];
  involvedPeople: IInvolvement[];
  handOverTime: Date | null;
  handOverOrderTime: Date | null;
  sbsExportAt: Date | null;
  excludeFromSbsExport: boolean;
  sbsId: string | null;
}

export interface IIncidentServiceState {
  overview: {
    incidents: IIncidentExcerpt[];
    filter: null | IIncidentsFilter;
  };
  sites: ISite[];
  incidentDetail: IIncidentDetail | null;
  incidentMediaUploading: string[];
  incidentAttachmentsUploading: string[];
}

export type IncidentUploadingKeys = 'incidentMediaUploading' | 'incidentAttachmentsUploading';

export enum ActionTypes {
  'Resolve' = 'Resolve',
  'Sign' = 'Sign'
}

export enum ExportStatus {
  'Exported' = 'Exported',
  'Excluded' = 'Excluded',
  'Pending' = 'Pending'
}

export interface IIncidentsFilter extends Omit<IFilterPayload, 'to' | 'from' | 'limit' | 'offset'> {
  from: Date | null;
  limit: number | null;
  offset: number | null;
  // 'to' intentionally not saved in store, as it's not a visible filter
}

export interface IFilterPayload extends Omit<IGetIncidentsRequestDto, 'from' | 'to' | 'limit' | 'offset'> {
  from?: Date | null;
  to?: Date | null;
  limit?: number | null;
  offset?: number | null;
}
