import { WeatherResponse } from '@/api/zbag-prd/weather/WeatherApiClient';
import { IPrdStations } from '@/areas/Assessments/store/types';
import { hydrateDataPoints } from '@/areas/Assessments/store/utils';
import { IWeather, SnowStation, TemperatureStation, WindStation } from '@/model';

export function mapWeatherData(response: WeatherResponse, stations: IPrdStations): IWeather {
  return {
    snow: Object.freeze({
      stationValues: response.snow.dataPoints.map(s => {
        return new SnowStation(
          stations.get(s.stationId) || s.stationId,
          s.stationId,
          s.isPrimary,
          hydrateDataPoints(s.freshSnow24H, v => v * 100 /* convert m to cm */),
          hydrateDataPoints(s.freshSnow72H, v => v * 100 /* convert m to cm */),
          hydrateDataPoints(s.snowDepth, v => v * 100 /* convert m to cm */)
        );
      })
    }),
    wind: Object.freeze({
      stationValues: response.wind.dataPoints.map((s: any) => {
        return new WindStation(
          stations.get(s.stationId) || s.stationId,
          s.stationId,
          s.isPrimary,
          hydrateDataPoints(s.windAverage3H),
          hydrateDataPoints(s.windAverage10Min),
          hydrateDataPoints(s.windPeak),
          hydrateDataPoints(s.windDirection)
        );
      })
    }),
    temperature: Object.freeze({
      stationValues: response.temperature.dataPoints.map((s: any) => {
        return new TemperatureStation(
          stations.get(s.stationId) || s.stationId,
          s.stationId,
          s.isPrimary,
          hydrateDataPoints(s.airTemperature),
          hydrateDataPoints(s.airHumidity),
          hydrateDataPoints(s.snowSurfaceTemperature),
          hydrateDataPoints(s.snowPackTemperature)
        );
      })
    })
  };
}
