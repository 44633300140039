import Vue from 'vue';
import VueI18n from 'vue-i18n';

import de from '../locales/de.json';

Vue.use(VueI18n);

export const config: VueI18n.I18nOptions = {
  locale: 'de',
  messages: {
    de: de
  }
};

export default new VueI18n(config);
