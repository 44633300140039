
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as Highcharts from 'highcharts';
import ResizeObserver from 'resize-observer-polyfill';
import { fromUnixTime } from 'date-fns';
import { WindStation } from '@/model';
import DataPointViz from '@/components/DataPointViz.vue';
import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue';
import { categoryColors } from '@/components/definitions';
import { format } from '@/plugins/format';
import { ChartDisplayTimeRange } from '@/model';

const chartCache = new WeakMap();

@Component({
  components: { DataPointListTimeViz, DataPointViz }
})
export default class WindChart extends Vue {
  @Prop()
  public station!: WindStation;

  @Prop()
  public displayTimeRange?: ChartDisplayTimeRange<Date>;

  @Watch('displayTimeRange')
  onDisplayTimeRangeChanged(newRange: ChartDisplayTimeRange<Date>) {
    const chart = chartCache.get(this);

    if (chart) {
      chart.update({
        xAxis: {
          min: this.defaultDisplayTimeRange.from,
          max: this.defaultDisplayTimeRange.to
        }
      });
    }
  }

  private resizeObserver!: ResizeObserver;

  get defaultDisplayTimeRange(): ChartDisplayTimeRange<number | null> {
    const from = this.displayTimeRange ? this.displayTimeRange.from.valueOf() : null;
    const to = this.displayTimeRange ? this.displayTimeRange.to.valueOf() : null;

    return {
      from,
      to
    };
  }

  public mounted() {
    let chartElement = this.$refs.chart as any;
    this.createChart(chartElement);
  }

  public destroyed() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private createChart(element: any) {
    let series = [
      {
        type: 'line',
        name: this.$t('dailyAssessment.conditions.windSituation.chartWindAverage') as string,
        color: categoryColors[0],
        data: this.station.average3h.map(d => [d.timestamp.valueOf(), d.value]).sort(),
        yAxis: 'left'
      },
      {
        type: 'line',
        name: this.$t('dailyAssessment.conditions.windSituation.chartWindPeak') as string,
        color: categoryColors[0],
        dashStyle: 'Dash',
        data: this.station.peak.map(d => [d.timestamp.valueOf(), d.value]).sort(),
        yAxis: 'left'
      },
      {
        type: 'line',
        name: this.$t('dailyAssessment.conditions.windSituation.chartWindDirection') as string,
        color: categoryColors[1],
        data: this.station.direction.map(d => [d.timestamp.valueOf(), d.value]).sort(),
        yAxis: 'right',
        lineWidth: 0,
        marker: {
          enabled: true,
          radius: 2
        },
        states: {
          hover: {
            lineWidthPlus: 0
          }
        }
      }
    ];

    const chart = Highcharts.chart(
      element,
      {
        title: { text: this.station.stationName },
        time: {
          timezoneOffset: new Date().getTimezoneOffset()
        },
        chart: {
          height: 200,
          spacingBottom: 0
        },
        credits: undefined,
        plotOptions: {
          line: {
            marker: { enabled: false }
          }
        },
        tooltip: {
          formatter: function() {
            let time = format(fromUnixTime(this.x / 1000), 'PPp');

            let unit = (this.series.yAxis as any).userOptions.id === 'left' ? 'km/h' : '°';

            return `<b>${this.series.getName()}</b><br>
                            <small>${time}</small><br>
                            <b>${this.y.toFixed(0)} ${unit}</b>`;
          }
        },
        xAxis: {
          type: 'datetime',
          min: this.defaultDisplayTimeRange.from,
          max: this.defaultDisplayTimeRange.to
        },
        yAxis: [
          {
            id: 'left',
            title: {
              text: this.$t('dailyAssessment.conditions.windSituation.chartSpeed') as string
            }
          },
          {
            id: 'right',
            title: {
              text: this.$t('dailyAssessment.conditions.windSituation.chartDirection') as string
            },
            opposite: true,
            visible: false,
            min: 0,
            max: 360
          }
        ],
        series: series as any,
        legend: {
          enabled: true
        }
      },
      undefined
    );

    chartCache.set(this, chart);

    if (ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => chart.reflow());
      this.resizeObserver.observe(element);
    }
  }
}
