
import DataPointListTimeViz from '@/components/DataPointListTimeViz.vue';
import DataPointViz from '@/components/DataPointViz.vue';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { SnowStation } from '@/model';

@Component({
  components: {
    DataPointViz,
    DataPointListTimeViz
  }
})
export default class DenseSnowStationView extends Vue {
  items!: SnowStation[];

  @Prop() stationValues!: SnowStation[];

  @Watch('stationValues', { immediate: true })
  onStationValuesChanged(newValues: SnowStation[]) {
    this.items = newValues;
  }
}
