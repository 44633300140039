
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FileUpload extends Vue {
  @Prop(Boolean) disabled!: boolean;
  @Prop({ type: String, default: '*/*' }) accept!: string;

  fileDropHovering = false;

  dropHovering(event: any) {
    this.fileDropHovering = true;
    event.dataTransfer.dropEffect = 'copy';
  }

  chooseFiles() {
    // credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    const fileUploadInput = this.$refs.fileUpload as HTMLInputElement;
    fileUploadInput.click();
  }

  selectFiles(event: any) {
    this.$emit('files-selected', event.target.files);
    // reset file input to allow uploading files with the same name again
    // https://stackoverflow.com/questions/20549241/how-to-reset-input-type-file
    const fileUploadInput = this.$refs.fileUpload as HTMLInputElement;
    fileUploadInput.value = '';
  }

  dropFiles(event: any) {
    this.fileDropHovering = false;
    this.$emit('files-selected', event.dataTransfer.files);
  }
}
