
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Assessment, IChangelog, User } from '@/model';
import { Getter } from 'vuex-class';
import { format } from '@/plugins/format';

@Component
export default class Changelog extends Vue {
  private get changeLog(): IChangelog[] {
    return this.assessment.changeLog;
  }

  @Prop()
  public assessment!: Assessment;

  @Getter('getUser')
  private getUser!: (userId: string) => User;

  format = format;

  /**
   * Returns the translation key for the given change property.
   * Replaces any dots with dashes as dots interfere with the translation resolution.
   */
  private propertyTranslationKey(propertyName: string) {
    return `dailyAssessment.changelog.properties.${propertyName.replace(/\./g, '-')}`;
  }
}
