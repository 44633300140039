
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { InputValidationRule } from 'vuetify';
import { parse, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

import { format } from '@/plugins/format';

@Component({})
export default class DateTimePicker extends Vue {
  @Prop({
    type: Array,
    default() {
      return [];
    }
  })
  timeRules!: InputValidationRule[];

  @Prop({
    type: Array,
    default() {
      return [];
    }
  })
  dateRules!: InputValidationRule[];

  @Prop(Date) value!: Date;
  @Prop(Date) minDate!: Date | null;
  @Prop(Date) maxDate!: Date | null;
  @Prop({ type: Boolean, default: true }) showDate!: boolean;
  @Prop({ type: Boolean, default: true }) showTime!: boolean;
  @Prop(String) timeLabel!: string;
  @Prop(String) dateLabel!: string;

  @Emit('input')
  emitInput(value: Date) {
    return value;
  }

  dateAndTime: Date = new Date(this.value); // clone date for local state management
  datePickerOpen = false;
  timePickerOpen = false;

  get displayedDate(): string {
    return format(this.dateAndTime, 'P');
  }

  get displayedTime(): string {
    return format(this.dateAndTime, 'p');
  }

  get time(): string {
    return format(this.dateAndTime, 'HH:mm');
  }
  set time(value: string) {
    this.dateAndTime = parse(value, 'HH:mm', this.dateAndTime, { locale: de });
    this.emitInput(this.dateAndTime);
  }

  get date(): string {
    return format(this.dateAndTime, 'yyyy-MM-dd');
  }
  set date(value: string) {
    this.dateAndTime = this.parseDate(value, 'yyyy-MM-dd', this.dateAndTime, { locale: de });
    this.emitInput(this.dateAndTime);
  }

  get pickerMinDate(): string | undefined {
    return this.minDate ? format(this.minDate, 'yyyy-MM-dd') : undefined;
  }

  get pickerMaxDate(): string | undefined {
    return this.maxDate ? format(this.maxDate, 'yyyy-MM-dd') : undefined;
  }

  getMonthDisplayFormat(isoString: string) {
    return format(parseISO(isoString), 'LLL');
  }

  /**
   * Helper function to reapply time after parsing due to bug in date-fns parse:
   * https://github.com/date-fns/date-fns/issues/3283
   */
  parseDate(...[value, format, referenceDate, options]: Parameters<typeof parse>) {
    const parsedDate = parse(value, format, referenceDate, options);
    const copiedReferenceDate = new Date(referenceDate);
    parsedDate.setHours(copiedReferenceDate.getHours());
    parsedDate.setMinutes(copiedReferenceDate.getMinutes());
    parsedDate.setSeconds(copiedReferenceDate.getSeconds());

    return parsedDate;
  }
}
