import Vue from 'vue';
import Vuex from 'vuex';
import assessmentsModule from '@/areas/Assessments/store';
import weatherModule from '@/areas/Weather/store';
import siteServiceModule from '@/areas/SiteService/store';
import { createOidcStore } from '@/areas/Oidc/store';
import rootMutations from '@/store/rootMutations';
import rootGetters from '@/store/rootGetters';
import rootActions from '@/store/rootActions';
import incidentServiceModule from '@/areas/IncidentService/store';
import statisticsModule from '@/areas/Statistics/store';

Vue.use(Vuex);

export const assessmentsNamespace = 'prd';
export const weatherNamespace = 'weather';
export const oidcNamespace = 'oidc';
export const siteServiceNamespace = 'siteService';
export const routeNamespace = 'route';
export const incidentServiceNamespace = 'incidentService';
export const statisticsNamespace = 'statistics';

const store = new Vuex.Store({
  state: {
    appError: null,
    appErrorMessage: null,
    appVersion: process.env.VUE_APP_VERSION,
    currentUser: null,
    regions: [],
    selectedRegion: null,
    users: [],
    sitesGeojson: null
  },
  mutations: rootMutations,
  getters: rootGetters,
  actions: rootActions,
  modules: {
    [oidcNamespace]: createOidcStore(runtimeAppSettings.oidcAuthority, runtimeAppSettings.rootUrl),
    [weatherNamespace]: weatherModule,
    [assessmentsNamespace]: assessmentsModule,
    [siteServiceNamespace]: siteServiceModule,
    [incidentServiceNamespace]: incidentServiceModule,
    [statisticsNamespace]: statisticsModule
  }
});

export default store;
