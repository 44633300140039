import { AppErrorKind } from './types';

interface TypedResponse<T = any> extends Response {
  /**
   * this will override `json` method from `Body` that is extended by `Response`
   * interface Body {
   *     json(): Promise<any>;
   * }
   */
  json<P = T>(): Promise<P>;
}

export default class ApiClient {
  private errorHandler: (error: AppErrorKind) => void = () => {};
  public defaultResultLimit = 60;

  constructor(protected readonly baseUrl: string, protected readonly accessTokenGetter: () => string) {}

  public registerErrorHandler(handler: (error: AppErrorKind) => void) {
    this.errorHandler = handler;
  }

  protected requestOptions(method: string, additionalOptions?: any): RequestInit {
    return Object.assign(
      {
        method: method,
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json'
        }
      },
      additionalOptions
    );
  }

  /**
   * Wrapper around window.fetch() that handles errors. Should be used for all API requests.
   */
  protected async fetch<T = any>(url: string, options: RequestInit): Promise<T | undefined> {
    try {
      const response = (await fetch(url, options)) as TypedResponse<T>;

      if (response.status === 204) {
        return;
      }

      if (this.hasErrors(response)) {
        switch (response.status) {
          case 404:
            this.errorHandler(AppErrorKind.ResourceNotFound);
            break;
          default:
            this.errorHandler(AppErrorKind.ApiError);
            break;
        }
        return;
      }

      if (response.headers.get('Content-Type')?.indexOf('application/json') === -1) {
        // simply forward responses that cannot be parsed as json
        return response as any;
      }

      try {
        return await response.json();
      } catch (e) {
        throw 'Failed to parse json!';
      }
    } catch (e) {
      this.errorHandler(AppErrorKind.ConnectionProblem);
      throw e;
    }
  }

  protected get accessToken() {
    return this.accessTokenGetter();
  }

  private hasErrors(response: Response) {
    if (!response.ok) {
      return true;
    }

    return false;
  }
}
