import { ActionTree } from 'vuex';
import { IRegion, IRootState } from '@/store/types';
import { usersClient, regionsClient, sitesClient } from '@/api/zbag-prd/httpClients';

const actionTree: ActionTree<IRootState, IRootState> = {
  /**
   * Fetches all regions from the API and updates the state.
   */
  async fetchRegions({ commit }) {
    const regions = await regionsClient.fetchRegions();
    commit('setRegions', regions);
  },
  /**
   * Fetches all users.
   */
  async fetchUsers({ commit }) {
    const users = await usersClient.fetchUsers();
    commit('setUsers', users);
  },
  async switchRegion({ commit, dispatch, state, rootState }, regionId: string) {
    const selectedRegion = rootState.regions.find((r: IRegion) => r.id === regionId);
    commit('selectRegion', selectedRegion);
  },
  async fetchSitesGeodata({ commit }): Promise<void> {
    const geojson = await sitesClient.getSitesGeojson();
    commit('addSitesGeojson', { geojson });
  }
};

export default actionTree;
