import { IIncidentDetailDto, IIncidentExcerptDto, IRescuerDto } from '@/api/zbag-prd/incidents/types';
import { ExportStatus, IIncidentDetail, IIncidentExcerpt, IRescuer } from '@/areas/IncidentService/store/types';
import { parseISO } from 'date-fns';
import { IAttachmentDto } from '@/api/zbag-prd/issues/types';
import { IAttachment } from '@/model';

export function hydrateIncidents(incidents: IIncidentExcerptDto[]): IIncidentExcerpt[] {
  return incidents.map(({ createdAt, incidentNumber, ...rest }) => {
    const alarmedDate = parseISO(rest.alarmedAt);
    const resolvedDate = rest.resolvedAt ? parseISO(rest.resolvedAt) : null;
    const signedDate = rest.signedAt ? parseISO(rest.signedAt) : null;
    const incidentId = incidentNumber!;
    const hydratedRescuers = rest.rescuers.length ? hydrateRescuers(rest.rescuers) : [];
    const sbsExportAt = rest.sbsExportAt ? parseISO(rest.sbsExportAt) : null;
    const excludeFromExport = rest.excludeFromSbsExport;
    const exportStatus = rest.sbsExportAt
      ? ExportStatus.Exported
      : rest.excludeFromSbsExport
      ? ExportStatus.Excluded
      : ExportStatus.Pending;

    return {
      ...rest,
      incidentId,
      alarmedAt: alarmedDate,
      resolvedAt: resolvedDate,
      signedAt: signedDate,
      rescuers: hydratedRescuers,
      sbsExportAt: sbsExportAt,
      excludeFromSbsExport: excludeFromExport,
      exportStatus: exportStatus
    };
  });
}

export function hydrateRescuers(rescuers: IRescuerDto[]): IRescuer[] {
  return rescuers.map(({ assignedAt, arrivedAt, ...rest }) => {
    const assignedDate = parseISO(assignedAt);

    return {
      ...rest,
      assignedAt: assignedDate,
      arrivedAt: arrivedAt ? parseISO(arrivedAt) : null
    };
  });
}

export function hydrateIncidentDetail(incident: IIncidentDetailDto): IIncidentDetail {
  const hydratedIncident = hydrateIncidents([incident])[0] as Omit<
    IIncidentDetail,
    'handOverOrderTime' | 'handOverTime'
  > & { handOverOrderTime: string; handOverTime: string };

  if (!hydratedIncident.involvedPeople) {
    hydratedIncident.involvedPeople = [];
  }

  if (!hydratedIncident.injuries) {
    hydratedIncident.injuries = [];
  }

  const handOverOrderTime = hydratedIncident.handOverOrderTime ? parseISO(hydratedIncident.handOverOrderTime) : null;
  const handOverTime = hydratedIncident.handOverTime ? parseISO(hydratedIncident.handOverTime) : null;

  return { ...hydratedIncident, handOverTime, handOverOrderTime };
}

export function hydrateAttachment(attachment: IAttachmentDto): IAttachment {
  return attachment;
}

export function downloadBlob(blob: Blob, fileName: string = 'export'): void {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);

  link.click();
}

export function getFileNameFromContentDisposition(disposition: string): string {
  // example: attachment; filename=Unfallstatistik_2020-09-10_2020-12-09.xlsx; filename*=UTF-8''Unfallstatistik_2020-09-10_2020-12-09.xlsx
  return disposition.split('filename=')[1].split(';')[0];
}
