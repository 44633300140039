import BaseApiClient from '@/api/utils/BaseApiClient';
import { User } from '@/model';

export default class UsersApiClient extends BaseApiClient {
  async fetchUsers(): Promise<User[]> {
    const response = await this.fetch(`${this.baseUrl}/users`, this.requestOptions('GET'));

    return response.users.map((u: any) => new User(u.id, u.firstName, u.lastName));
  }
}
