export const categoryColors = [
  '#1b5c88',
  '#63b8e7',
  '#e37f27',
  '#ee2c26',
  '#b0b060',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf'
];

export const fillColors = [
  'rgba(99,184,231,.3)' // '#63b8e7'
];
