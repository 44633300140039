
import { Vue, PropSync, Component } from 'vue-property-decorator';
import Toast from '@/components/common/Toast/Toast.vue';

@Component({
  components: {
    Toast
  }
})
export default class ResourceNotFoundError extends Vue {
  @PropSync('isVisible') syncedIsVisible!: boolean;
}
