import Router, { Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import { parseISO } from 'date-fns';
import * as Sentry from '@sentry/browser';

import OidcCallback from '@/views/OidcCallback.vue';
import Conditions from '@/views/dailyAssessment/Conditions.vue';
import Forecast from '@/views/dailyAssessment/Forecast.vue';
import CourseOfAction from '@/views/dailyAssessment/CourseOfAction.vue';
import { IRootState } from '@/store/types';
import Passthrough from '@/views/Passthrough.vue';
import Changelog from '@/views/dailyAssessment/Changelog.vue';
import { asyncRouteGuard } from '@/plugins/async-route-guard';
import NoMatchingRegion from '@/components/NoMatchingRegion.vue';
import { asyncComponentFactory, ensureReturnToParams, resolveDefaultRegionId } from '@/router/utils';
import { defaultRegionIdPlaceholder, RouteNames } from '@/router/constants';
import { incidentServiceNamespace, oidcNamespace } from '@/store';
import { InspectionKind } from '@/api/zbag-prd/inspections/types';
import IncidentService from '@/areas/IncidentService/views/IncidentService.vue';
import { Regions, Role } from '@/model';
import IncidentCollisionReport from '@/areas/IncidentService/views/IncidentCollisionReport.vue';

const vuexOidc = require('vuex-oidc');

const Weather = () => asyncComponentFactory(import(/* webpackChunkName: "module-1" */ '@/views/Weather.vue'));
const AssessmentSelector = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-1" */ '@/views/AssessmentSelector.vue'));
const DailyAssessment = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-1" */ '../views/DailyAssessment.vue'));
const SiteService = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/SiteService.vue'));
const SiteServiceOverview = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/SiteServiceOverview.vue'));
const AddInspection = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/AddInspection.vue'));
const Site = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/Site.vue'));
const AddIssue = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/AddIssue.vue'));
const Inspection = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/Inspection.vue'));
const Issue = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/Issue.vue'));
const SiteServiceHistory = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-2" */ '@/areas/SiteService/views/SiteServiceHistory.vue'));
const IncidentServiceOverview = () =>
  asyncComponentFactory(
    import(/* webpackChunkName: "module-3" */ '@/areas/IncidentService/views/IncidentServiceOverview.vue')
  );
const AddIncident = () => {
  return asyncComponentFactory(
    import(/* webpackChunkName: "module-3" */ '@/areas/IncidentService/views/AddIncident.vue')
  );
};
const Incident = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-3" */ '@/areas/IncidentService/views/Incident.vue'));
const Statistics = () =>
  asyncComponentFactory(import(/* webpackChunkName: "module-3" */ '@/areas/Statistics/views/Statistics.vue'));

export function createRouter(store: Store<IRootState>): Router {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: RouteNames.Home,
        redirect: {
          name: RouteNames.UserLoadedRoot
        }
      },
      {
        path: '/oidc-callback', // Needs to match redirect_uri in oidcClientSettings
        name: RouteNames.OidcCallback,
        component: OidcCallback,
        meta: {
          isOidcCallback: true,
          isPublic: true
        }
      },
      {
        // This is a common parent route that ensures all child routes have the OIDC user information
        // already present
        path: '',
        name: RouteNames.UserLoadedRoot,
        component: Passthrough,
        beforeEnter: asyncRouteGuard(async (to: Route, from: Route, next: (p?: any) => void) => {
          await Promise.all([
            store.dispatch('oidc/getOidcUser'),
            store.dispatch('fetchRegions'),
            store.dispatch('fetchUsers')
          ]);

          const isRedirectFromHome = from.redirectedFrom === '/' || to.fullPath === '/';

          if (isRedirectFromHome) {
            const hasRole = store.getters[`${oidcNamespace}/hasRole`];
            const isSnowcatDriver = hasRole(Role.PrdSnowcatDriver);

            if (isSnowcatDriver) {
              next({
                name: RouteNames.SiteServiceOverview,
                params: { regionId: defaultRegionIdPlaceholder }
              });
              return;
            }

            next({
              name: RouteNames.WeatherPage,
              params: { regionId: defaultRegionIdPlaceholder }
            });
            return;
          }

          next();
        }),
        children: [
          {
            path: '/weather',
            name: RouteNames.WeatherRoot,
            redirect: {
              name: RouteNames.WeatherPage,
              // redirect to exact path as at this stage the regionId is not yet resolved and always defaults to the first region
              path: '/weather/:regionId'
            },
            component: Passthrough,
            children: [
              {
                path: ':regionId',
                name: RouteNames.WeatherPage,
                component: Weather,
                props: true,
                beforeEnter: asyncRouteGuard(async (to: Route, from: Route, next: (p?: any) => void) => {
                  await resolveDefaultRegionId(to, from, next);
                  await store.dispatch('weather/fetchWeather', {
                    regionId: to.params.regionId
                  });
                })
              }
            ],
            beforeEnter: asyncRouteGuard(async (to: Route, from: Route, next: (p?: any) => void) => {
              next();
            })
          },
          {
            path: '/daily-assessment',
            name: RouteNames.DailyAssessmentRoot,
            redirect: {
              name: RouteNames.DailyAssessmentSelector,
              params: { regionId: defaultRegionIdPlaceholder }
            },
            component: Passthrough,
            children: defineDailyAssessmentRoutes(store),
            beforeEnter: asyncRouteGuard(async (to: Route, from: Route, next: (p?: any) => void) => {
              // Fetch static data
              await store.dispatch('prd/fetchStations');
              next();
            })
          },
          {
            path: '/slope-service',
            name: RouteNames.SiteServiceRoot,
            component: Passthrough,
            props: true,
            redirect: {
              name: RouteNames.SiteServiceOverview,
              params: { regionId: defaultRegionIdPlaceholder }
            },
            beforeEnter: asyncRouteGuard(async (to, from, next) => {
              const user = store.getters.currentUser;

              if (!user) {
                next(false);
                return;
              }

              next();
            }),
            children: [
              {
                path: ':regionId',
                component: SiteService,
                beforeEnter: asyncRouteGuard(resolveDefaultRegionId),
                children: [
                  {
                    path: '',
                    name: RouteNames.SiteServiceOverview,
                    component: SiteServiceOverview,
                    props: true
                  },
                  {
                    path: RouteNames.SiteServiceHistorySlug,
                    name: RouteNames.SiteServiceHistory,
                    component: SiteServiceHistory,
                    props: true
                  },
                  {
                    path: 'add-final-inspection',
                    name: RouteNames.SiteServiceAddFinalInspection,
                    component: AddInspection,
                    beforeEnter: ensureReturnToParams,
                    props: route => ({
                      regionId: route.params.regionId,
                      siteId: route.query.siteId,
                      kind: InspectionKind.Final,
                      returnTo: route.query.returnTo
                    })
                  },
                  {
                    path: 'add-technical-inspection',
                    name: RouteNames.SiteServiceAddTechnicalInspection,
                    component: AddInspection,
                    beforeEnter: ensureReturnToParams,
                    props: route => ({
                      regionId: route.params.regionId,
                      siteId: route.query.siteId,
                      kind: InspectionKind.Technical,
                      returnTo: route.query.returnTo
                    })
                  },
                  {
                    path: 'add-issue',
                    name: RouteNames.SiteServiceAddIssue,
                    beforeEnter: ensureReturnToParams,
                    component: AddIssue,
                    props: route => ({
                      regionId: route.params.regionId,
                      siteId: route.query.siteId,
                      returnTo: route.query.returnTo
                    })
                  },
                  {
                    path: 'site/:siteId',
                    name: RouteNames.SiteServiceSite,
                    component: Site,
                    props: route => ({ regionId: route.params.regionId, siteId: route.params.siteId })
                  },
                  {
                    path: 'site/:siteId/inspections/:kind/:createdAt',
                    name: RouteNames.SiteServiceInspection,
                    component: Inspection,
                    props: route => ({
                      regionId: route.params.regionId,
                      createdAt: route.params.createdAt,
                      siteId: route.params.siteId,
                      kind: route.params.kind,
                      returnTo: route.query.returnTo
                    })
                  },
                  {
                    path: 'site/:siteId/issues/:createdAt',
                    name: RouteNames.SiteServiceIssue,
                    component: Issue,
                    props: route => ({
                      regionId: route.params.regionId,
                      createdAt: route.params.createdAt,
                      siteId: route.params.siteId,
                      returnTo: route.query.returnTo
                    })
                  }
                ]
              },
              {
                path: '*',
                component: NoMatchingRegion
              }
            ]
          },
          {
            path: '/rescue-service',
            name: RouteNames.IncidentServiceRoot,
            redirect: to => {
              const { params } = to;
              const hasRole = store.getters[`${oidcNamespace}/hasRole`];
              const shouldGroupToWinter: boolean = hasRole([Role.PrdDispatcher, Role.PrdSupervisor, Role.PrdViewer]);

              return {
                name: RouteNames.IncidentServiceOverview,
                params: {
                  regionId: params.regionId || (shouldGroupToWinter ? Regions.Winter : defaultRegionIdPlaceholder)
                }
              };
            },
            component: Passthrough,
            beforeEnter: asyncRouteGuard(async (to, from, next) => {
              // fetch commonly needed data in advance for all sub-routes
              await store.dispatch(`${incidentServiceNamespace}/fetchAllSites`);
              await store.dispatch(`fetchSitesGeodata`);

              next();
            }),
            children: [
              {
                path: ':regionId',
                component: IncidentService,
                beforeEnter: asyncRouteGuard(resolveDefaultRegionId),
                children: [
                  {
                    path: '',
                    name: RouteNames.IncidentServiceOverview,
                    component: IncidentServiceOverview,
                    props: true
                  },
                  {
                    path: 'add-incident',
                    name: RouteNames.IncidentServiceAddIncident,
                    component: AddIncident,
                    props: route => ({ siteId: route.params.siteId, regionId: route.params.regionId })
                  },
                  {
                    path: 'incident/:incidentId',
                    name: RouteNames.IncidentServiceIncident,
                    component: Incident,
                    props: route => ({
                      incidentId: route.params.incidentId,
                      isEditMode: Boolean(route.query.isEditMode)
                    }),
                    beforeEnter: asyncRouteGuard(async (to, from, next) => {
                      await store.dispatch(`${incidentServiceNamespace}/fetchIncidentDetail`, to.params.incidentId);
                      next();
                    })
                  },
                  {
                    path: 'incident/:incidentId/collision-report',
                    name: RouteNames.IncidentCollisionReport,
                    component: IncidentCollisionReport,
                    beforeEnter: asyncRouteGuard(async (to, from, next) => {
                      await store.dispatch(`${incidentServiceNamespace}/fetchIncidentDetail`, to.params.incidentId);
                      next();
                    })
                  }
                ]
              }
            ]
          },
          {
            path: '/statistics',
            name: RouteNames.Statistics,
            component: Statistics
          }
        ]
      }
    ],
    scrollBehavior: (to, from, scrollBehaviour) => {
      // always scroll to top on navigation
      return { x: 0, y: 0 };
    }
  });

  router.beforeEach((to, from, next): void => {
    const vuexOidcCreateRouterMiddleware = vuexOidc.vuexOidcCreateRouterMiddleware(store, 'oidc');
    vuexOidcCreateRouterMiddleware(to, from, next);
  });

  router.onError(error => {
    Sentry.captureException(error);
  });

  return router;
}

function defineDailyAssessmentRoutes(store: Store<IRootState>): RouteConfig[] {
  return [
    {
      path: ':regionId',
      name: RouteNames.DailyAssessmentSelector,
      component: AssessmentSelector,
      props: true,
      beforeEnter: asyncRouteGuard(async (to: Route, from: Route, next: (p?: any) => void) => {
        await resolveDefaultRegionId(to, from, next);
        await store.dispatch('prd/fetchAssessments', {
          regionId: to.params.regionId
        });
      }),
      children: [
        {
          path: ':assessmentId',
          name: RouteNames.DailyAssessment,
          component: DailyAssessment,
          props: true,
          beforeEnter: asyncRouteGuard(
            async (to: Route, from: Route, next: (p?: any) => void): Promise<void> => {
              await store.dispatch('prd/fetchAssessment', {
                region: (store.state as IRootState).selectedRegion,
                createdAt: parseISO(to.params.assessmentId)
              });
              next();
            }
          ),
          children: [
            {
              path: 'conditions',
              name: RouteNames.DailyAssessmentConditions,
              component: Conditions
            },
            {
              path: 'forecast',
              name: RouteNames.DailyAssessmentForecast,
              component: Forecast
            },
            {
              path: 'course-of-action',
              name: RouteNames.DailyAssessmentCourseOfAction,
              component: CourseOfAction
            },
            {
              path: 'changelog',
              name: RouteNames.DailyAssessmentChangelog,
              component: Changelog,
              // re-fetch assessment because changelog is never updated from within the application
              beforeEnter: asyncRouteGuard(async (to: Route, from: Route, next: (p?: any) => void) => {
                await store.dispatch('prd/fetchAssessment', {
                  region: (store.state as IRootState).selectedRegion,
                  createdAt: parseISO(to.params.assessmentId)
                });
                next();
              })
            }
          ]
        }
      ]
    }
  ];
}
