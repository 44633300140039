import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IRootState } from '@/store/types';
import { Module } from 'vuex';
import { ISiteServiceState } from '@/areas/SiteService/store/types';

const siteServiceModule: Module<ISiteServiceState, IRootState> = {
  namespaced: true,
  state: {
    issuesMediaUploading: [],
    issuesAttachmentsUploading: [],
    sites: [],
    overview: {
      issues: [],
      inspections: []
    },
    siteDetail: null,
    issueDetail: null,
    inspectionDetail: null,
    issueFilter: {
      status: 'Open',
      sort: '-CreatedAt'
    },
    inspectionsForSelectedDate: [],
    selectedDateForHistoricalInspections: null
  },
  mutations,
  actions,
  getters
};

export default siteServiceModule;
