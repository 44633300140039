
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { subHours } from 'date-fns';
import { Assessment, ICustomAvalancheBulletin, OwnObservations } from '@/model';
import WindSituation from '@/components/WindSituation.vue';
import AttachmentList from '@/components/common/AttachmentList/AttachmentList.vue';
import SnowSituation from '@/components/SnowSituation.vue';
import TemperatureSituation from '@/components/TemperatureSituation.vue';
import AvalancheSituation from '@/components/AvalancheSituation.vue';
import Impressions from '@/components/Impressions.vue';
import { cloneDeep } from 'lodash-es';
import { Action, State } from 'vuex-class';
import FileUploadingList from '@/components/common/FileUploadingList/FileUploadingList.vue';
import { ChartDisplayTimeRange } from '@/model';
import FileUpload from '@/components/common/FileUpload/FileUpload.vue';

@Component({
  components: {
    FileUpload,
    FileUploadingList,
    Impressions,
    AvalancheSituation,
    TemperatureSituation,
    SnowSituation,
    WindSituation,
    AttachmentList
  }
})
export default class Conditions extends Vue {
  public get notes(): string {
    return this.assessment.currentSituation.ownObservations.notes;
  }

  public set notes(notes: string) {
    // TODO: debounce
    this.updateOwnObservations(ownObservations => (ownObservations.notes = notes));
  }

  public get chartDisplayTimeRange(): ChartDisplayTimeRange<Date> {
    return {
      from: subHours(this.assessment.createdAt, this.availableRanges[this.selectedTimeRange]),
      to: this.assessment.createdAt
    };
  }

  get availableRanges(): number[] {
    // list of available time ranges in hours
    return [6, 12, 72];
  }

  @Prop()
  public assessment!: Assessment;

  @Prop()
  public editMode!: boolean;

  @Action('prd/uploadAttachment')
  public uploadAttachment!: (file: File) => void;

  @Action('prd/deleteAttachment')
  deleteAttachment!: (id: string) => void;

  @State('fileUploadProcesses', { namespace: 'prd' })
  public fileUploadProcesses!: string[];

  selectedTimeRange: number = this.availableRanges.length - 1;
  customAvalancheBulletin: ICustomAvalancheBulletin | null = null;

  public updateSnowLine(snowLine: string | null) {
    this.updateOwnObservations(ownObservations => (ownObservations.snowLine = Number(snowLine)));
  }

  public updateWeatherObservations(weatherObservations: string[]) {
    this.updateOwnObservations(ownObservations => (ownObservations.weatherObservations = weatherObservations));
  }

  public updateCloudObservation(cloudObservation: string | null) {
    this.updateOwnObservations(ownObservations => (ownObservations.cloudObservation = cloudObservation));
  }

  public updateWindStrengthObservation(windStrengthObservation: string | null) {
    this.updateOwnObservations(ownObservations => (ownObservations.windStrengthObservation = windStrengthObservation));
  }

  public updateWindDirectionObservation(windDirectionObservation: number | null) {
    this.updateOwnObservations(
      ownObservations => (ownObservations.windDirectionObservation = windDirectionObservation)
    );
  }

  public updateSnowPackObservations(snowPackObservations: string[]) {
    this.updateOwnObservations(ownObservations => (ownObservations.snowPackObservations = snowPackObservations));
  }

  public updateAvalancheActivityObservations(avalancheActivityObservations: string[]) {
    this.updateOwnObservations(
      ownObservations => (ownObservations.avalancheActivityObservations = avalancheActivityObservations)
    );
  }

  public updateAvalancheProblemObservations(avalancheProblemObservations: string[]) {
    this.updateOwnObservations(
      ownObservations => (ownObservations.avalancheProblemObservations = avalancheProblemObservations)
    );
  }

  @Watch('assessment.currentSituation.ownObservations.customAvalancheBulletin', { immediate: true, deep: true })
  watchCustomAvalancheBulletin(customAvalancheBulletinFromProps: ICustomAvalancheBulletin): void {
    if (customAvalancheBulletinFromProps) {
      this.customAvalancheBulletin = { ...customAvalancheBulletinFromProps };
    } else {
      this.customAvalancheBulletin = null;
    }
  }

  public updateCustomAvalancheBulletin(customAvalancheBulletin: ICustomAvalancheBulletin | null) {
    this.customAvalancheBulletin = customAvalancheBulletin;
    this.updateOwnObservations(ownObservations => (ownObservations.customAvalancheBulletin = customAvalancheBulletin));
  }

  private uploadFiles(files: FileList) {
    const filesArray = Array.from(files);

    if (!files.length) {
      return;
    }

    filesArray.forEach((item: File) => this.uploadAttachment(item));
  }

  @Emit('update:own-observations')
  private updateOwnObservations(updater: (ownObservations: OwnObservations) => void) {
    let ownObservations = this.getOwnObservationsCopy();
    updater(ownObservations);
    return ownObservations;
  }

  private getOwnObservationsCopy(): OwnObservations {
    return cloneDeep(this.assessment.currentSituation.ownObservations) as OwnObservations;
  }
}
