import { render, staticRenderFns } from "./ExpositionSelector.vue?vue&type=template&id=195049fc&scoped=true"
import script from "./ExpositionSelector.vue?vue&type=script&lang=ts"
export * from "./ExpositionSelector.vue?vue&type=script&lang=ts"
import style0 from "./ExpositionSelector.vue?vue&type=style&index=0&id=195049fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195049fc",
  null
  
)

export default component.exports