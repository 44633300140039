import BaseApiClient from '@/api/utils/BaseApiClient';
import { IRegion } from '@/store/types';

export default class RegionsApiClient extends BaseApiClient {
  async fetchRegions(): Promise<IRegion[]> {
    const response = await this.fetch(`${this.baseUrl}/regions`, this.requestOptions('GET'));

    return response.regions;
  }
}
