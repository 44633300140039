import { MutationTree } from 'vuex';

import {
  IIncidentDetail,
  IIncidentExcerpt,
  IIncidentServiceState,
  IIncidentsFilter,
  IncidentUploadingKeys,
  IRescuer
} from '@/areas/IncidentService/store/types';
import { ISite } from '@/areas/SiteService/store/types';
import { IAttachment } from '@/model';
import { HandOverKind, IncidentStatus } from '@/api/zbag-prd/incidents/types';

interface IAddIncidentsOverviewDataMutation {
  incidents: IIncidentExcerpt[];
}

interface IAddAllSitesMutation {
  sites: ISite[];
}

interface IAddIncidentDetailMutation {
  incident: IIncidentDetail;
}

interface IAssignRescuerMutation {
  rescuer: IRescuer;
}

interface IUnAssignRescuerMutation {
  userId: string;
}

interface IPendingUploadMutation {
  name: string;
  stateKey: IncidentUploadingKeys;
}

interface IAddAttachmentMutation {
  attachment: IAttachment;
}

interface IRemoveAttachmentMutation {
  attachmentId: string;
}

export interface IUpdateStatusMutation {
  status: Exclude<IncidentStatus, IncidentStatus.Open>;
  signedAt: Date | null;
  resolvedAt: Date | null;
}

export interface IFilterMutation extends IIncidentsFilter {}

const mutations: MutationTree<IIncidentServiceState> = {
  addIncidentsOverviewData(state, { incidents }: IAddIncidentsOverviewDataMutation): void {
    state.overview.incidents = incidents;
  },
  addAllSites(state, { sites }: IAddAllSitesMutation): void {
    state.sites = sites;
  },
  addIncidentDetail(state, { incident }: IAddIncidentDetailMutation): void {
    state.incidentDetail = incident;
  },
  assignRescuer(state, { rescuer }: IAssignRescuerMutation): void {
    const rescuers = state.incidentDetail?.rescuers;

    if (rescuers) {
      const index = rescuers.findIndex(item => item.userId === rescuer.userId);

      if (index > -1) {
        rescuers.splice(index, 1, rescuer);
      } else {
        rescuers.push(rescuer);
      }
    }
  },
  unAssignRescuer(state, { userId }: IUnAssignRescuerMutation): void {
    if (state.incidentDetail) {
      state.incidentDetail.rescuers = state.incidentDetail.rescuers.filter(rescuer => rescuer.userId !== userId);
    }
  },
  addPendingUpload(state: IIncidentServiceState, { stateKey, name }: IPendingUploadMutation) {
    state[stateKey].push(name);
  },
  removePendingUpload(state: IIncidentServiceState, { stateKey, name }: IPendingUploadMutation) {
    const index = state[stateKey].indexOf(name);

    if (index > -1) {
      state[stateKey].splice(index, 1);
    }
  },
  addAttachment(state: IIncidentServiceState, { attachment }: IAddAttachmentMutation): void {
    state.incidentDetail?.attachments.push(attachment);
  },
  removeAttachment(state: IIncidentServiceState, { attachmentId }: IRemoveAttachmentMutation): void {
    if (state.incidentDetail) {
      state.incidentDetail.attachments = state.incidentDetail.attachments.filter(
        attachment => attachment.id !== attachmentId
      );
    }
  },
  updateIncidentStatus(state: IIncidentServiceState, { status, resolvedAt, signedAt }: IUpdateStatusMutation): void {
    if (state.incidentDetail) {
      state.incidentDetail.resolvedAt = resolvedAt;
      state.incidentDetail.status = status;
      state.incidentDetail.signedAt = signedAt;
    }
  },
  setFilter(state: IIncidentServiceState, payload: IFilterMutation): void {
    state.overview.filter = payload;
  }
};

export default mutations;
