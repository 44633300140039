import BaseApiClient from '@/api/utils/BaseApiClient';
import {
  IAssignRescuerRequestDto,
  ICreateIncidentAttachmentDto,
  ICreateIncidentRequestDto,
  IDeleteIncidentAttachmentDto,
  IDeleteIncidentDto,
  IGetIncidentsRequestDto,
  IIncidentDetailDto,
  IIncidentListDto,
  IRescuerDto,
  IUpdateIncidentRequestDto,
  IUpdateIncidentStatusDto
} from '@/api/zbag-prd/incidents/types';
import {
  downloadBlob,
  getFileNameFromContentDisposition,
  hydrateAttachment,
  hydrateIncidentDetail,
  hydrateIncidents,
  hydrateRescuers
} from '@/api/zbag-prd/incidents/utils';
import { IIncidentDetail, IIncidentExcerpt, IRescuer } from '@/areas/IncidentService/store/types';
import { IAttachmentDto } from '@/api/zbag-prd/issues/types';
import { IAttachment } from '@/model';

export default class IncidentsApiClient extends BaseApiClient {
  async getIncidents({ regionId, status, from, to, offset, limit, rescuer }: IGetIncidentsRequestDto = {}): Promise<
    IIncidentExcerpt[]
  > {
    const queryParams = new URLSearchParams();

    if (from) {
      queryParams.set('from', from);
    }

    if (to) {
      queryParams.set('to', to);
    }

    if (offset) {
      queryParams.set('offset', String(offset));
    }

    if (limit) {
      queryParams.set('limit', String(limit));
    }

    if (regionId) {
      queryParams.set('region', regionId);
    }

    if (status) {
      queryParams.set('status', status);
    }

    if (rescuer) {
      queryParams.set('rescuer', rescuer);
    }

    const response = await this.fetch<IIncidentListDto>(
      `${this.baseUrl}/incidents?${queryParams.toString()}`,
      this.requestOptions('GET')
    );

    if (response && response.incidents) {
      return hydrateIncidents(response.incidents);
    }

    return [];
  }

  async getIncident(incidentId: string): Promise<IIncidentDetail | null> {
    const response = await this.fetch<IIncidentDetailDto>(
      `${this.baseUrl}/incidents/${incidentId}`,
      this.requestOptions('GET')
    );

    if (response) {
      return hydrateIncidentDetail(response);
    }

    return null;
  }

  async createIncident(payload: ICreateIncidentRequestDto): Promise<IIncidentDetail | null> {
    const requestBody = JSON.stringify({ ...payload, isPaid: payload.isPaid || false });

    const response = await this.fetch<IIncidentDetailDto>(
      `${this.baseUrl}/incidents`,
      this.requestOptions('POST', { body: requestBody })
    );

    if (response) {
      return hydrateIncidentDetail(response);
    }

    return null;
  }

  async updateIncident({
    incidentId,
    regionId,
    location,
    title,
    siteId,
    notes,
    patient,
    sport,
    injuries,
    handOverKind,
    isCollision,
    isPaid,
    involvedPeople,
    paymentKind,
    painkillersGiven,
    painkillersNotes,
    alarmedAt,
    handOverTime,
    handOverOrderTime,
    handOverDestination,
    excludeFromSbsExport
  }: IUpdateIncidentRequestDto): Promise<IIncidentDetail | null> {
    const requestBody = JSON.stringify({
      location,
      title,
      siteId,
      regionId,
      notes,
      patient,
      sport,
      injuries,
      isCollision,
      handOverKind,
      isPaid,
      involvedPeople,
      paymentKind,
      alarmedAt,
      painkillersGiven,
      painkillersNotes,
      handOverTime,
      handOverOrderTime,
      handOverDestination,
      excludeFromSbsExport
    });

    const response = await this.fetch<IIncidentDetailDto>(
      `${this.baseUrl}/incidents/${incidentId}`,
      this.requestOptions('PUT', { body: requestBody })
    );

    if (response) {
      return hydrateIncidentDetail(response);
    }

    return null;
  }

  async assignRescuer({ userId, incidentId, arrivedAt }: IAssignRescuerRequestDto): Promise<IRescuer | null> {
    const requestBody = JSON.stringify({
      userId,
      arrivedAt: arrivedAt ? arrivedAt.toISOString() : undefined
    });

    const response = await this.fetch<IRescuerDto>(
      `${this.baseUrl}/incidents/${incidentId}/rescuers`,
      this.requestOptions('PUT', { body: requestBody })
    );

    if (response) {
      return hydrateRescuers([response])[0];
    }

    return null;
  }

  async unAssignRescuer({ userId, incidentId }: IAssignRescuerRequestDto): Promise<void> {
    return await this.fetch<void>(
      `${this.baseUrl}/incidents/${incidentId}/rescuers/${userId}`,
      this.requestOptions('DELETE')
    );
  }

  async createIncidentAttachment({ incidentId, file }: ICreateIncidentAttachmentDto): Promise<IAttachment | null> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const response = await this.fetch<IAttachmentDto>(
      `${this.baseUrl}/incidents/${incidentId}/attachments`,
      this.requestOptions('POST', {
        body: formData,
        headers: {
          Authorization: `Bearer ${this.accessToken}`
          // Intentionally do not define 'Content-Type' header, as the browser will correctly infer it including the boundary parameter
        }
      })
    );

    if (response) {
      return hydrateAttachment(response);
    }

    return null;
  }

  async deleteIncidentAttachment({ incidentId, attachmentId }: IDeleteIncidentAttachmentDto): Promise<void> {
    return await this.fetch(
      `${this.baseUrl}/incidents/${incidentId}/attachments/${attachmentId}`,
      this.requestOptions('DELETE')
    );
  }

  async updateStatus({ incidentId, incidentStatus }: IUpdateIncidentStatusDto): Promise<IIncidentDetail | null> {
    const body = JSON.stringify({ status: incidentStatus });
    const response = await this.fetch<IIncidentDetailDto>(
      `${this.baseUrl}/incidents/${incidentId}/status`,
      this.requestOptions('PUT', {
        body
      })
    );

    if (response) {
      return hydrateIncidentDetail(response);
    }

    return null;
  }

  async deleteIncident({ incidentId }: IDeleteIncidentDto): Promise<void> {
    return await this.fetch<void>(`${this.baseUrl}/incidents/${incidentId}`, this.requestOptions('DELETE'));
  }

  async exportIncidents(): Promise<void> {
    const response = await this.fetch<Response>(
      `${this.baseUrl}/incidents/export`,
      this.requestOptions('GET', { responseType: 'blob' })
    );

    if (response) {
      const disposition = response.headers.get('Content-Disposition');
      const fileName = disposition ? getFileNameFromContentDisposition(disposition) : undefined;
      const blob = await response.blob();

      downloadBlob(blob, fileName);
    }

    return;
  }

  async exportIncidentsByRescuer(): Promise<void> {
    const response = await this.fetch<Response>(
      `${this.baseUrl}/incidents/export/by-rescuer`,
      this.requestOptions('GET', { responseType: 'blob' })
    );

    if (response) {
      const disposition = response.headers.get('Content-Disposition');
      const fileName = disposition ? getFileNameFromContentDisposition(disposition) : undefined;
      const blob = await response.blob();

      downloadBlob(blob, fileName);
    }

    return;
  }

  async exportAccountingReport(monthAndYear: string): Promise<void> {
    const response = await this.fetch<Response>(
      `${this.baseUrl}/incidents/export/month/${monthAndYear}`,
      this.requestOptions('GET', {
        responseType: 'blob'
      })
    );

    if (response) {
      const disposition = response.headers.get('Content-Disposition');
      const fileName = disposition ? getFileNameFromContentDisposition(disposition) : undefined;
      const blob = await response.blob();

      downloadBlob(blob, fileName);
    }

    return;
  }

  async exportIncidentToSbs(): Promise<void> {
    return await this.fetch(`${this.baseUrl}/sbs-export`, this.requestOptions('POST'));
  }
}
