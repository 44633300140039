
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class ImagePreview extends Vue {
  @Prop({
    default: 'white'
  })
  public iconPreviewColor!: string;

  @Prop({
    default: 'white'
  })
  public iconCloseColor!: string;

  private preview = false;

  private togglePreview() {
    this.preview = !this.preview;
  }
}
