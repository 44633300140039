
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import { debounce } from 'lodash-es';
import Geometry from 'ol/geom/Geometry';
import { isToday } from 'date-fns';

import { showDenseSiteOverlayUntilZoomLevel } from '@/areas/SiteService/components/MapManager/constants';
import { MapContext } from '@/areas/SiteService/components/MapManager/types';
import { ICustomGeojsonProperties } from '@/store/types';
import SiteMarker from '@/components/common/SwissTopoMap/SiteMarker.vue';
import { ISitesIndexed } from '@/areas/SiteService/store/types';
import { INormalizedFeature } from '@/components/common/SwissTopoMap/types';
import { SiteKind } from '@/api/zbag-prd/sites/types';
import { VIcon } from 'vuetify/lib';
import { getCenter } from '@/components/common/SwissTopoMap/utils';

@Component({
  components: { SiteMarker },
  inheritAttrs: false
})
export default class MapSiteOverlays extends Vue {
  @Prop(Object) mapContext!: MapContext;
  @Prop(Array) features!: Feature<Geometry>[];
  @Prop(String) linkToRouteName?: string;
  @Prop(Object) sitesById?: ISitesIndexed;
  @Prop(Array) visibleRegionIds?: string[];
  @Prop(Array) selectedSiteIds?: string[];

  @Watch('features')
  onFeaturesChanged() {
    this.rebuildOverlays();
  }

  private xxSmall: boolean = false;
  private overlays?: Overlay[]; // keep track of overlays so that they can be removed later. Intentionally undefined to avoid reactivity

  get normalizedFeatures(): INormalizedFeature[] {
    return this.features.map(feature => {
      const { id, number, region, kind, name, color } = feature.getProperties() as ICustomGeojsonProperties;

      const statistics = this.sitesById && this.sitesById[id] && this.sitesById[id].statistics;
      let to, finalInspectionDone, technicalInspectionDone, numberOfOpenIssues;

      if (this.linkToRouteName) {
        to = { name: this.linkToRouteName, params: { regionId: region, siteId: id } };
      }

      if (statistics) {
        finalInspectionDone = isToday(statistics.lastFinalInspection);
        technicalInspectionDone = isToday(statistics.lastTechnicalInspection);
        numberOfOpenIssues = statistics.openIssuesCount;
      }

      return {
        id,
        number,
        region,
        kind,
        name,
        color,
        to,
        finalInspectionDone,
        technicalInspectionDone,
        numberOfOpenIssues,
        isSelected: this.selectedSiteIds?.includes(id) || false
      };
    });
  }

  initOverlays() {
    const map = this.mapContext.map!;
    const overlays = this.features.flatMap(this.createOverlay);

    if (overlays.length) {
      overlays.forEach(overlay => map.addOverlay(overlay));

      map.on('moveend', debounce(this.handleMoveEnd, 150));

      this.overlays = overlays;
    }
  }

  rebuildOverlays() {
    // old overlays need to be removed from ol to avoid junk in DOM
    if (this.overlays) {
      this.overlays.forEach(overlay => this.mapContext.map?.removeOverlay(overlay));
      this.overlays = [];
    }

    // need to wait 1 tick for the $refs to update
    this.$nextTick(this.initOverlays);
  }

  mounted() {
    this.initOverlays();
  }

  handleMoveEnd() {
    const map = this.mapContext.map!;
    const currentZoomLevel = map.getView().getZoom() || 0;
    this.xxSmall = currentZoomLevel < showDenseSiteOverlayUntilZoomLevel;
  }

  createOverlay(feature: Feature<Geometry>, index: number): Overlay[] {
    const overlay = this.$refs.overlayRefs as HTMLElement[];

    return [
      new Overlay({
        className: 'site-overlay',
        element: overlay[index],
        positioning: 'center-center',
        position: getCenter(feature)
      })
    ];
  }

  getSymbolForFeature(feature: INormalizedFeature) {
    const iconComponentFactory = (name: string, icon: string, color: string | undefined = undefined) => ({
      name,
      render(createElement: any) {
        return createElement(VIcon, { props: { color, small: true } }, icon);
      }
    });

    switch (feature.kind) {
      case SiteKind.Fence:
        return iconComponentFactory('Fence', 'mdi-pine-tree', 'green');
      case SiteKind.SosStation:
        return iconComponentFactory('SOS', 'mdi-hospital-box-outline');
      case SiteKind.SnowPark:
        return iconComponentFactory('FunPark', 'mdi-snowboard');
      case SiteKind.FunSlope:
        return iconComponentFactory('FunSlope', 'mdi-ski');
      default: {
        if (feature.number !== '') {
          return {
            render(createElement: any) {
              return createElement('span', feature.number);
            }
          };
        } else {
          return iconComponentFactory('UnnumberedSite', 'mdi-octagram', '#999');
        }
      }
    }
  }

  isMarkerVisible(feature: INormalizedFeature): boolean {
    const visibleRegionIds = this.visibleRegionIds;

    if (visibleRegionIds !== undefined) {
      return visibleRegionIds.includes(feature.region);
    }
    // show Marker by default
    return true;
  }
}
