import { Store } from 'vuex';
import { IRootState } from '@/store/types';
import { defaultRegionIdPlaceholder } from '@/router/constants';
import store from '@/store';
import { Route } from 'vue-router';
import { AppErrorKind } from '@/api/utils/types';

export function getDefaultRegionId(store: Store<IRootState>): string | null {
  const oidcUser = (store.state as any).oidc.user;
  return oidcUser.zbag && oidcUser.zbag.regions ? oidcUser.zbag.regions.split(',')[0] : null;
}

export async function resolveDefaultRegionId(to: Route, from: Route, next: (p?: any) => void) {
  if (to.params.regionId === defaultRegionIdPlaceholder) {
    const storeState = store.state as IRootState;
    to.params.regionId = storeState.selectedRegion?.id || getDefaultRegionId(store) || storeState.regions[0].id;
    next(to);
  } else {
    await store.dispatch('switchRegion', to.params.regionId);
    next();
  }
}

export function ensureReturnToParams(to: Route, from: Route, next: (p?: any) => void) {
  // ensure siteId and regionId is set when coming from site detail view.
  // this is relevant to redirect back to site detail later.
  if (from.params.siteId) {
    to.params.siteId = from.params.siteId;
  }

  if (from.params.regionId) {
    to.params.regionId = from.params.regionId;
  }

  next();
}

export function asyncComponentFactory(component: Promise<any>): Promise<any> {
  return component.catch(e => {
    // loading chunk failed. ex. due to stale html file after new deployment.
    // display an error asking the user to refresh the page
    store.commit('setAppError', {
      error: AppErrorKind.ChunkLoadError
    });

    return {
      __esModule: true,
      default: null
    };
  });
}
