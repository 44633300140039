import BaseApiClient from '@/api/utils/BaseApiClient';
import { IIssue } from '@/areas/SiteService/store/types';
import {
  IAttachmentDto,
  ICreateIssueAttachmentDto,
  ICreateIssueRequestDto,
  IDeleteIssueAttachmentDto,
  IGetIssuesForSiteRequestDto,
  IGetIssuesRequestDto,
  IIssueDto,
  IIssueIdDto,
  IIssueListDto,
  IUpdateIssueRequestDto
} from '@/api/zbag-prd/issues/types';
import { hydrateIssues } from '@/api/zbag-prd/issues/utils';

export default class IssuesApiClient extends BaseApiClient {
  async getIssuesForRegion({
    regionId,
    from,
    to,
    status,
    createdBy,
    sort,
    limit = this.defaultResultLimit
  }: IGetIssuesRequestDto): Promise<IIssue[]> {
    const queryParams = new URLSearchParams();

    if (from) {
      queryParams.set('from', from);
    }

    if (to) {
      queryParams.set('to', to);
    }

    if (sort) {
      queryParams.set('sort', sort);
    }

    if (status) {
      queryParams.set('status', status);
    }

    if (createdBy && createdBy !== null) {
      queryParams.set('createdBy', createdBy);
    }

    queryParams.set('limit', String(limit));

    const response = await this.fetch<IIssueListDto>(
      `${this.baseUrl}/regions/${regionId}/issues?${queryParams.toString()}`,
      this.requestOptions('GET')
    );

    if (response && response.issues) {
      return hydrateIssues(response.issues);
    }

    return [];
  }

  async getIssuesForSite({
    regionId,
    siteId,
    limit = this.defaultResultLimit,
    to,
    from,
    status,
    createdBy,
    sort
  }: IGetIssuesForSiteRequestDto): Promise<IIssue[]> {
    const queryParams = new URLSearchParams();

    if (from) {
      queryParams.set('from', from);
    }

    if (to) {
      queryParams.set('to', to);
    }

    if (status) {
      queryParams.set('status', status);
    }

    if (sort) {
      queryParams.set('sort', sort);
    }

    if (createdBy) {
      queryParams.set('createdBy', createdBy);
    }

    queryParams.set('limit', String(limit));

    const response = await this.fetch<IIssueListDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues?${queryParams.toString()}`,
      this.requestOptions('GET')
    );

    if (response && response.issues) {
      return hydrateIssues(response.issues);
    }

    return [];
  }

  async getSingleIssue({ regionId, siteId, createdAt }: IIssueIdDto): Promise<IIssue | null> {
    const response = await this.fetch<IIssueDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}`,
      this.requestOptions('GET')
    );

    if (response) {
      return hydrateIssues([response])[0];
    }

    return null;
  }

  async createIssue({ regionId, siteId, title }: ICreateIssueRequestDto): Promise<IIssue | null> {
    const payload = JSON.stringify({
      title
    });

    const response = await this.fetch<IIssueDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues`,
      this.requestOptions('POST', {
        body: payload
      })
    );

    if (response) {
      return hydrateIssues([response])[0];
    }

    return null;
  }

  async updateIssue({
    title,
    notes,
    location,
    regionId,
    siteId,
    createdAt
  }: IUpdateIssueRequestDto): Promise<IIssue | null> {
    const nullableNotes = notes === '' ? null : notes;
    const payload = JSON.stringify({
      title,
      notes: nullableNotes,
      location
    });

    const response = await this.fetch<IIssueDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}`,
      this.requestOptions('PUT', {
        body: payload
      })
    );

    if (response) {
      return hydrateIssues([response])[0];
    }

    return null;
  }

  async createIssueAttachment({ regionId, siteId, createdAt, file }: ICreateIssueAttachmentDto) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return await this.fetch<IAttachmentDto>(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}/attachments`,
      this.requestOptions('POST', {
        body: formData,
        headers: {
          Authorization: `Bearer ${this.accessToken}`
          // Intentionally do not define 'Content-Type' header, as the browser will correctly infer it including the boundary parameter
        }
      })
    );
  }

  async deleteIssueAttachment({ regionId, siteId, createdAt, attachmentId }: IDeleteIssueAttachmentDto): Promise<void> {
    return await this.fetch(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}/attachments/${attachmentId}`,
      this.requestOptions('DELETE')
    );
  }

  async resolveIssue({ regionId, siteId, createdAt }: IIssueIdDto): Promise<IIssue | undefined> {
    const issue = await this.fetch(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}/resolve`,
      this.requestOptions('PUT')
    );

    if (issue) {
      return hydrateIssues([issue])[0];
    }
  }

  async reopenIssue({ regionId, siteId, createdAt }: IIssueIdDto): Promise<IIssue | undefined> {
    const issue = await this.fetch(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}/reopen`,
      this.requestOptions('PUT')
    );

    if (issue) {
      return hydrateIssues([issue])[0];
    }
  }

  async deleteIssue({ regionId, siteId, createdAt }: IIssueIdDto): Promise<void> {
    return await this.fetch(
      `${this.baseUrl}/regions/${regionId}/sites/${siteId}/issues/${createdAt}`,
      this.requestOptions('DELETE')
    );
  }
}
