
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ShowMoreButton extends Vue {
  @Prop(Boolean) isExpanded!: boolean;

  @Watch('isExpanded')
  onIsExpandedChanged(newValue: boolean): void {
    this.internalIsExpanded = newValue;
  }

  internalIsExpanded = false;

  handleClick(e: Event): void {
    this.internalIsExpanded = !this.internalIsExpanded;
    this.$emit('update:isExpanded', this.internalIsExpanded);
  }
}
