export enum InspectionKind {
  Technical = 'Technical',
  Final = 'Final'
}

export interface IFilterInspectionsQuery {
  from?: string;
  to?: string;
  limit?: number;
  kind?: InspectionKind;
}

export interface ICreateInspectionDto {
  kind: InspectionKind;
  notes: string | null;
  createdBy?: string;
  createdAt: Date;
  siteId: string;
  regionId: string;
}

export interface IInspectionDto {
  kind: InspectionKind;
  notes: string | null;
  createdAt: string;
  createdBy: string;
  siteId: string;
}

export interface IInspectionListDto {
  inspections: IInspectionDto[];
}

export interface IGetInspectionsForRegionRequestDto extends IFilterInspectionsQuery {
  regionId: string;
}

export interface IGetInspectionRequestDto {
  kind: InspectionKind;
  regionId: string;
  siteId: string;
  createdAt: string;
}

export interface IGetInspectionsForSiteRequestDto extends IFilterInspectionsQuery {
  regionId: string;
  siteId: string;
}

export interface IDeleteInspectionRequestDto {
  kind: InspectionKind;
  createdAt: string;
  regionId: string;
  siteId: string;
}
