
import { Vue, PropSync, Component, Prop } from 'vue-property-decorator';
import Toast from '@/components/common/Toast/Toast.vue';

@Component({
  components: {
    Toast
  }
})
export default class ValidationError extends Vue {
  @PropSync('isVisible') syncedIsVisible!: boolean;
  @Prop(String) message!: string;
}
