import { ActionTree } from 'vuex';

import { issuesClient, sitesClient, inspectionsClient } from '@/api/zbag-prd/httpClients';
import { IIssue, ISiteServiceState } from '@/areas/SiteService/store/types';
import { IRootState } from '@/store/types';
import { IAddInspectionForm } from '@/areas/SiteService/components/AddInspectionForm/types';
import { IGetSingleSiteRequestDto } from '@/api/zbag-prd/sites/types';
import { startOfToday } from 'date-fns';
import {
  IDeleteInspectionRequestDto,
  IGetInspectionRequestDto,
  IGetInspectionsForRegionRequestDto,
  InspectionKind
} from '@/api/zbag-prd/inspections/types';
import {
  IAttachmentDto,
  IBareIssue,
  ICreateIssueAttachmentDto,
  ICreateIssueRequestDto,
  IDeleteIssueAttachmentDto,
  IGetIssuesRequestDto,
  IIssueIdDto,
  IUpdateIssueRequestDto
} from '@/api/zbag-prd/issues/types';
import { IPartialIssueFilter } from '@/areas/SiteService/components/IssuesList/types';

export interface ICreateInspectionActionPayload extends IAddInspectionForm {
  regionId: string;
  kind: InspectionKind;
}
export interface IFetchOverviewDataActionPayload extends Partial<IGetInspectionsForRegionRequestDto> {}
export interface IFetchInspectionsForRegionActionPayload extends IGetInspectionsForRegionRequestDto {}
export interface IFetchInspectionDetailActionPayload extends IGetInspectionRequestDto {}
export interface IDeleteInspectionActionPayload extends IDeleteInspectionRequestDto {}
export interface IGetIssuesActionPayload extends IGetIssuesRequestDto {}
export interface ICreateIssueActionPayload extends ICreateIssueRequestDto {}
export interface IUpdateIssueActionPayload extends IUpdateIssueRequestDto {}
export interface IUploadIssueAttachmentActionPayload extends ICreateIssueAttachmentDto {
  stateKey: string;
}
export interface IDeleteIssueAttachmentActionPayload extends IDeleteIssueAttachmentDto {}
export interface IFetchSiteDetailActionPayload extends IGetSingleSiteRequestDto {}
export interface IFetchIssueDetailActionPayload extends IIssueIdDto {}
export interface IModifyIssueStateActionPayload extends IIssueIdDto {}
export interface IApplyIssueFilterActionPayload extends IPartialIssueFilter {}
export interface IFetchIssuesForSiteActionPayload extends IGetIssuesRequestDto {
  siteId: string;
}
export interface IFetchSitesForRegionActionPayload {
  regionId: string;
  includeStatistics?: boolean;
}

const actions: ActionTree<ISiteServiceState, IRootState> = {
  async fetchSitesForRegion(
    { commit, dispatch, rootGetters },
    { regionId, includeStatistics }: IFetchSitesForRegionActionPayload
  ): Promise<void> {
    const sites = await sitesClient.getSites(regionId, includeStatistics);

    if (sites.length) {
      commit('addSitesForRegion', { sites });
    }
  },
  async createInspections(
    { commit, rootGetters },
    { siteIds, createdAt, createdBy, notes, regionId, kind }: ICreateInspectionActionPayload
  ): Promise<void> {
    for (const siteId of siteIds) {
      const result = await inspectionsClient.postInspection({
        regionId,
        siteId,
        createdAt,
        createdBy,
        notes,
        kind
      });

      if (result) {
        commit('addNewInspection', { inspection: result });
      }
    }
  },
  async fetchOverviewData(
    { dispatch, getters, rootGetters },
    { regionId, limit }: IFetchOverviewDataActionPayload
  ): Promise<void> {
    const startOfTodayISOString = startOfToday().toISOString();

    if (!rootGetters.sitesGeojson) {
      await dispatch('fetchSitesGeodata', null, { root: true });
    }

    // fetch sites to annotate inspections
    await dispatch('fetchSitesForRegion', { regionId, includeStatistics: true });

    // get inspections of today
    await dispatch('fetchInspectionsOverview', {
      regionId,
      from: startOfTodayISOString,
      limit
    });

    // get issues
    await dispatch('fetchIssuesOverview', { regionId });
  },
  async fetchInspectionsOverview(
    { commit, rootGetters },
    { regionId, from, limit }: IFetchInspectionsForRegionActionPayload
  ): Promise<void> {
    const technicalInspections = await inspectionsClient.getInspectionsForRegion({
      regionId,
      from,
      limit,
      kind: InspectionKind.Technical
    });
    const finalInspections = await inspectionsClient.getInspectionsForRegion({
      regionId,
      from,
      limit,
      kind: InspectionKind.Final
    });

    if (technicalInspections && finalInspections) {
      commit('addInspectionsOverview', {
        inspections: technicalInspections.concat(finalInspections)
      });
    }
  },
  async fetchInspectionDetail({ commit, rootGetters }, payload: IFetchInspectionDetailActionPayload): Promise<void> {
    const inspection = await inspectionsClient.getInspection(payload);
    const site = await sitesClient.getSingleSite({ regionId: payload.regionId, siteId: payload.siteId });

    if (inspection && site) {
      commit('addInspectionDetail', {
        inspection,
        site
      });
    }
  },
  async deleteInspection({ commit }, payload: IDeleteInspectionActionPayload): Promise<void> {
    await inspectionsClient.deleteInspection(payload);

    commit('removeInspection', payload);
  },
  async fetchIssuesOverview({ commit, rootGetters, getters }, payload: IGetIssuesActionPayload): Promise<void> {
    const currentlyAppliedFilter = getters.currentIssueFilter;
    const issues = await issuesClient.getIssuesForRegion({
      ...payload,
      ...currentlyAppliedFilter
    });

    if (issues) {
      commit('addIssuesForRegion', {
        issues
      });
    }
  },
  async applyIssueFilter({ commit, dispatch }, payload: IApplyIssueFilterActionPayload): Promise<void> {
    commit('applyIssueFilter', payload);
  },
  async createIssue({ commit, rootGetters }, payload: ICreateIssueActionPayload): Promise<IBareIssue | undefined> {
    const issue = await issuesClient.createIssue(payload);

    if (issue) {
      // not recommended but necessary due to the 2-step form process
      return issue;
    } else {
      throw 'Failed to create issue';
    }
  },
  async updateIssue({ commit, rootGetters }, payload: IUpdateIssueActionPayload): Promise<IIssue> {
    const issue = await issuesClient.updateIssue(payload);

    if (issue) {
      return issue;
    } else {
      throw 'Failed to update issue';
    }
  },
  async uploadIssueAttachment(
    { commit },
    payload: IUploadIssueAttachmentActionPayload
  ): Promise<IAttachmentDto | undefined> {
    const fileName = payload.file.name;
    let issueAttachmentDto = null;

    commit('addPendingUpload', { name: fileName, stateKey: payload.stateKey });

    try {
      issueAttachmentDto = await issuesClient.createIssueAttachment(payload);
      commit('removePendingUpload', { name: fileName, stateKey: payload.stateKey });
      return issueAttachmentDto;
    } catch (e) {
      commit('removePendingUpload', { name: fileName, stateKey: payload.stateKey });
      return;
    }
  },
  async deleteIssueAttachment({ commit }, payload: IDeleteIssueAttachmentActionPayload): Promise<void> {
    try {
      return await issuesClient.deleteIssueAttachment(payload);
    } catch (e) {
      // error already logged by API request
      return;
    }
  },
  async fetchSiteDetail(
    { commit, state, rootGetters, dispatch },
    { siteId, regionId }: IFetchSiteDetailActionPayload
  ): Promise<void> {
    dispatch('fetchIssuesForSite', { siteId, regionId });

    const site = await sitesClient.getSingleSite({ regionId, siteId });
    const inspections = await inspectionsClient.getInspectionsForSite({ regionId, siteId });

    if (site) {
      commit('addSiteDetail', {
        site,
        inspections
      });
    }
  },
  async fetchIssuesForSite({ commit, getters }, payload: IFetchIssuesForSiteActionPayload): Promise<void> {
    const currentlyAppliedIssueFilter = getters.currentIssueFilter;
    const issues = await issuesClient.getIssuesForSite({
      ...payload,
      ...currentlyAppliedIssueFilter
    });

    if (issues) {
      commit('addIssuesToSiteDetail', { issues });
    }
  },
  async fetchIssueDetail(
    { commit, state, dispatch, rootGetters },
    payload: IFetchIssueDetailActionPayload
  ): Promise<void> {
    const issue = await issuesClient.getSingleIssue(payload);
    const site = await sitesClient.getSingleSite(payload);

    if (!rootGetters.sitesGeojson) {
      await dispatch('fetchSitesGeodata', null, { root: true });
    }

    if (issue) {
      commit('addIssueDetail', {
        issue,
        site
      });
    }
  },
  async markIssueAsDone({ commit, state }, payload: IModifyIssueStateActionPayload): Promise<void> {
    const issue = await issuesClient.resolveIssue(payload);

    if (issue) {
      commit('updateIssueDetail', {
        issue
      });
    }
  },
  async markIssueAsOpen({ commit, state }, payload: IModifyIssueStateActionPayload): Promise<void> {
    const issue = await issuesClient.reopenIssue(payload);

    if (issue) {
      commit('updateIssueDetail', {
        issue
      });
    }
  },
  async deleteIssue({ commit, state }, payload: IModifyIssueStateActionPayload): Promise<void> {
    await issuesClient.deleteIssue(payload);

    commit('removeIssueDetail');
  },
  async fetchInspectionsForDate({ commit, state }, payload: IFetchInspectionsForRegionActionPayload): Promise<void> {
    const inspections = await inspectionsClient.getInspectionsForRegion(payload);

    commit('addInspectionsForDate', { inspections });
  }
};

export default actions;
