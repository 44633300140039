import { Module } from 'vuex';
import { addMonths, endOfMonth, isEqual, isSameSecond, startOfMonth, subMonths } from 'date-fns';
import { cloneDeep, groupBy } from 'lodash-es';

import { format } from '@/plugins/format';
import { reconstituteAssessment } from '@/areas/Assessments/store/utils';

import { Assessment, IAssessmentSummary, IAttachment, ICourseOfAction, IStation, OwnObservations } from '@/model';
import { IRegion, IRootState } from '@/store/types';
import { assessmentsClient } from '@/api/zbag-prd/httpClients';
import { IPrdState, IRegionAssessments } from './types';

const assessmentsModule: Module<IPrdState, IRootState> = {
  namespaced: true,
  state: {
    assessmentSelectorDate: new Date(),
    stations: new Map<string, string>(),
    assessments: {},
    selectedAssessment: null,
    fileUploadProcesses: []
  },
  mutations: {
    setAssessmentSelectorDate(state: IPrdState, date: Date) {
      state.assessmentSelectorDate = date;
    },
    setStations(state: IPrdState, stations: IStation[]) {
      state.stations.clear();
      stations.forEach(station => state.stations.set(station.id, station.name));
    },
    setAssessments(state: IPrdState, assessments: IRegionAssessments) {
      state.assessments = assessments;
    },
    selectAssessment(state: IPrdState, assessment: Assessment) {
      const dayIndex = format(assessment.createdAt, 'yyyy-MM-dd');
      const assessments = state.assessments[dayIndex];

      if (!assessments) {
        // No day entry yet -> add day entry with assessment
        state.assessments[dayIndex] = [assessment];
      } else if (!assessments.some(a => isSameSecond(a.createdAt, assessment.createdAt))) {
        // Day entry exists, but assessment not yet there -> add to existing assessments at day
        state.assessments[dayIndex] = [...assessments, assessment];
      }

      state.selectedAssessment = assessment;
    },
    updateOwnObservations(state: IPrdState, ownObservations: OwnObservations) {
      if (state.selectedAssessment) {
        state.selectedAssessment.currentSituation.ownObservations = ownObservations;
      }
    },
    updateCourseOfAction(state: IPrdState, courseOfAction: ICourseOfAction) {
      if (state.selectedAssessment) {
        state.selectedAssessment.courseOfAction = courseOfAction;
      }
    },
    updateForecast(state: IPrdState, development: string) {
      if (state.selectedAssessment) {
        state.selectedAssessment.currentSituation.ownObservations.weatherDevelopment = development;
      }
    },
    addAttachment(state: IPrdState, attachment: IAttachment) {
      if (state.selectedAssessment) {
        state.selectedAssessment.currentSituation.attachments.push(attachment);
      }
    },
    deleteAttachment(state: IPrdState, attachmentId: string) {
      if (state.selectedAssessment) {
        const index = state.selectedAssessment.currentSituation.attachments.findIndex((attachment: IAttachment) => {
          return attachment.id === attachmentId;
        });

        if (index > -1) {
          state.selectedAssessment.currentSituation.attachments.splice(index, 1);
        }
      }
    },
    deleteAssessment(state: IPrdState, { regionId, createdAt }) {
      const dayIndex = format(createdAt, 'yyyy-MM-dd');
      state.assessments[dayIndex] = state.assessments[dayIndex].filter((assessment: IAssessmentSummary) => {
        const matchesRegionId = assessment.region === regionId;
        const matchesCreatedAt = isEqual(assessment.createdAt, createdAt);
        return !matchesRegionId || !matchesCreatedAt;
      });
    },
    addUploadProcess(state: IPrdState, name: string) {
      state.fileUploadProcesses.push(name);
    },
    removeUploadProcess(state: IPrdState, name: string) {
      const index = state.fileUploadProcesses.indexOf(name);

      if (index > -1) {
        state.fileUploadProcesses.splice(index, 1);
      }
    }
  },
  actions: {
    /**
     * Fetches all stations from the API and updates the state.
     */
    async fetchStations({ commit, rootState }: any) {
      const stations = await assessmentsClient.fetchStations();
      commit('setStations', stations);
    },
    /**
     * Fetches the assessments for the given region.
     * A range of 12 months in the past and 1 month ahead the current 'date' is fetched.
     */
    async fetchAssessments({ commit, rootState, state }: any, { regionId, date }) {
      const baseDate = date || state.assessmentSelectorDate;
      const from = startOfMonth(subMonths(baseDate, 12));
      const to = endOfMonth(addMonths(baseDate, 1));

      const assessments = await assessmentsClient.fetchAssessments(regionId, from, to);

      commit(
        'setAssessments',
        groupBy(assessments, a => format(a.createdAt, 'yyyy-MM-dd'))
      );
    },
    async fetchAssessment({ commit, rootState }: any, { region, createdAt }) {
      const assessmentResult = await assessmentsClient.fetchAssessment(region, createdAt);
      const assessment = reconstituteAssessment(assessmentResult, rootState.prd.stations);
      commit('selectAssessment', assessment);
    },
    /**
     * Creates a new assessment within the given region.
     */
    async createAssessment({ commit, rootState }: any, region: IRegion): Promise<Assessment> {
      const assessmentResult = await assessmentsClient.createAssessment(region);
      const assessment = reconstituteAssessment(assessmentResult, rootState.prd.stations);
      commit('selectAssessment', assessment);

      return assessment;
    },
    async updateOwnObservations({ commit, state, rootState }: any, ownObservations: OwnObservations) {
      const newAssessment = cloneDeep(state.selectedAssessment);
      newAssessment.currentSituation.ownObservations = ownObservations;

      try {
        await assessmentsClient.updateAssessment(newAssessment);
        commit('updateOwnObservations', ownObservations);
      } catch (e) {
        const resetOwnObservations = cloneDeep(state.selectedAssessment.currentSituation.ownObservations);
        commit('updateOwnObservations', resetOwnObservations);
      }
    },
    async updateCourseOfAction({ commit, state, rootState }: any, courseOfAction: ICourseOfAction) {
      const newAssessment = cloneDeep(rootState.prd.selectedAssessment);
      newAssessment.courseOfAction = courseOfAction;

      try {
        await assessmentsClient.updateAssessment(newAssessment);
        commit('updateCourseOfAction', courseOfAction);
      } catch (e) {
        const resetCourseOfAction = cloneDeep(state.selectedAssessment.courseOfAction);
        commit('updateCourseOfAction', resetCourseOfAction);
      }
    },
    async updateForecast({ commit, state, rootState }: any, development: string) {
      const newAssessment = cloneDeep(state.selectedAssessment);
      newAssessment.currentSituation.ownObservations.weatherDevelopment = development;

      const backup = state.selectedAssessment.currentSituation.ownObservations.weatherDevelopment;

      try {
        // order matters. 'development' is a primitive value, so we need to commit the changes first,
        // in order to be able to reset it on failure later
        commit('updateForecast', development);
        await assessmentsClient.updateAssessment(newAssessment);
      } catch (e) {
        commit('updateForecast', backup);
      }
    },
    async uploadAttachment({ commit, rootState }: any, file: File) {
      let result;
      const fileName = file.name;

      commit('addUploadProcess', fileName);

      try {
        result = await assessmentsClient.uploadAttachment(
          rootState.prd.selectedAssessment.region,
          rootState.prd.selectedAssessment.createdAt,
          file
        );
      } catch (e) {
        return commit('removeUploadProcess', fileName);
      }

      commit('removeUploadProcess', fileName);

      if (result) {
        commit('addAttachment', result);
      }
    },
    async deleteAttachment({ commit, rootState }: any, attachmentId: string) {
      await assessmentsClient.deleteAttachment(
        rootState.prd.selectedAssessment.region,
        rootState.prd.selectedAssessment.createdAt,
        attachmentId
      );

      commit('deleteAttachment', attachmentId);
    },
    async finalizeAssessment({ commit, rootState, dispatch }: any, { regionId, createdAt }) {
      await assessmentsClient.finalizeAssessment(regionId, createdAt);
      dispatch('fetchAssessment', { region: { id: regionId }, createdAt });
    },
    async deleteAssessment({ commit, rootState }: any, { regionId, createdAt }) {
      await assessmentsClient.deleteAssessment(regionId, createdAt);

      commit('deleteAssessment', { regionId, createdAt });
    }
  },
  getters: {
    regionAssessments: state => (region: string) => {
      let assessments: IAssessmentSummary[] = [];

      for (const date in state.assessments) {
        const assessmentsForRegion = state.assessments[date].filter(assessment => assessment.region === region);

        if (assessmentsForRegion) {
          assessments = assessments.concat(assessmentsForRegion);
        }
      }

      assessments.sort((leftAssessment, rightAssessment) => {
        return leftAssessment.createdAt < rightAssessment.createdAt ? -1 : 1;
      });
      return assessments;
    }
  }
};

export default assessmentsModule;
