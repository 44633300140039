
import { Action, Getter, Mutation, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

import { AppErrorKind } from '@/api/utils/types';
import { defaultRegionIdPlaceholder, RouteNames } from '@/router/constants';
import AppErrorMessage from '@/components/common/Errors/AppErrorMessage';
import { ISetAppErrorMutation } from '@/store/rootMutations';
import { IRegion } from '@/store/types';
import { oidcNamespace } from '@/store';
import { Regions, Role } from '@/model';
import MobileMenu from '@/components/MobileMenu.vue';
import DesktopMenu from '@/components/DesktopMenu.vue';

interface IRegionTabItem {
  id: string;
  name: string;
  to: Location;
}

@Component({
  components: { DesktopMenu, MobileMenu, AppErrorMessage }
})
export default class App extends Vue {
  @State('appError')
  appError!: AppErrorKind | null;

  @State('appErrorMessage')
  appErrorMessage?: string | null;

  @State('regions')
  regions!: IRegion[];

  @State('selectedRegion')
  selectedRegion!: IRegion | null;

  @Mutation('setAppError')
  setAppError!: (appError: ISetAppErrorMutation | null) => void;

  @Getter('oidcUser', { namespace: oidcNamespace })
  oidcUser!: any;

  @Getter('hasRole', { namespace: oidcNamespace })
  hasRole!: (role: Role | Role[]) => boolean;

  @Getter('hasAccessToRegion', { namespace: oidcNamespace })
  hasAccessToRegion!: (regionId: string) => boolean;

  @Action('signOutOidc', { namespace: 'oidc' })
  signOut!: () => Promise<void>;

  private AppErrorKind = AppErrorKind; // Required to make the enum work in templates
  private drawer = null;
  private loading = false;

  get navigationItems() {
    const regionIdToLinkTo = this.selectedRegion?.id || defaultRegionIdPlaceholder;

    return [
      {
        to: {
          name: RouteNames.WeatherRoot,
          params: { regionId: regionIdToLinkTo }
        },
        icon: 'mdi-weather-partly-cloudy',
        text: this.$t('app.menu.weather'),
        isVisible: true
      },
      {
        to: {
          name: RouteNames.DailyAssessmentSelector,
          params: { regionId: regionIdToLinkTo }
        },
        icon: 'mdi-clipboard-text',
        text: this.$t('app.menu.dailyAssessment'),
        isVisible: !this.hasRole([Role.PrdSnowcatDriver])
      },
      {
        to: {
          name: RouteNames.SiteServiceOverview,
          params: { regionId: regionIdToLinkTo }
        },
        icon: 'mdi-ski',
        text: this.$t('app.menu.siteService'),
        isVisible: true,
        children: [
          {
            to: {
              name: RouteNames.SiteServiceOverview,
              params: { regionId: regionIdToLinkTo }
            },
            text: this.$t('app.menu.siteServiceOverview'),
            isVisible: this.$vuetify.breakpoint.mdAndUp
          },
          {
            to: {
              name: RouteNames.SiteServiceHistory,
              params: { regionId: regionIdToLinkTo }
            },
            text: this.$t('app.menu.siteServiceHistory'),
            isVisible: this.$vuetify.breakpoint.mdAndUp && !this.hasRole([Role.PrdSnowcatDriver])
          }
        ]
      },
      {
        to: {
          name: RouteNames.IncidentServiceRoot,
          params: {
            regionId: this.hasRole([Role.PrdSupervisor, Role.PrdDispatcher, Role.PrdViewer])
              ? regionIdToLinkTo === Regions.SuedSummer
                ? Regions.SuedSummer
                : Regions.Winter
              : regionIdToLinkTo
          }
        },
        icon: 'mdi-hospital-box-outline',
        text: this.$t('app.menu.incidentService'),
        isVisible: !this.hasRole([Role.PrdSnowcatDriver])
      },
      {
        to: {
          name: RouteNames.Statistics
        },
        icon: 'mdi-file-chart-outline',
        text: this.$t('app.menu.statistics'),
        isVisible:
          this.$vuetify.breakpoint.mdAndUp && this.hasRole([Role.PrdViewer, Role.PrdSupervisor, Role.PrdDispatcher])
      }
    ];
  }

  get accessibleRegions(): IRegionTabItem[] {
    let regionsToShow: IRegion[] = [];

    if (this.hasRole([Role.PrdSupervisor, Role.PrdViewer, Role.PrdDispatcher])) {
      if (this.$route.path.includes('rescue-service')) {
        regionsToShow.push({
          id: Regions.Winter,
          name: this.$t('app.menu.allRegionsName') as string
        });

        const suedSummer = this.regions.find(region => region.id === Regions.SuedSummer);
        suedSummer ? regionsToShow.push(suedSummer) : null;

        return regionsToShow.map(this.getRegionTabItem);
      }

      regionsToShow = regionsToShow.concat(this.regions);
    } else {
      regionsToShow = regionsToShow.concat(this.regions.filter(region => this.hasAccessToRegion(region.id)));
    }

    return regionsToShow.map(this.getRegionTabItem);
  }

  get useBottomNavigation() {
    const visibleNavigationItems = this.navigationItems.filter(item => item.isVisible);
    return this.$vuetify.breakpoint.smAndDown && visibleNavigationItems.length > 1;
  }

  get useDesktopNavigation() {
    const visibleNavigationItems = this.navigationItems.filter(item => item.isVisible);
    return visibleNavigationItems.length > 1;
  }

  get isPWAMode() {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      // @ts-ignore
      window.navigator.standalone ||
      document.referrer.includes('android-app://')
    );
  }

  get isStatisticsRoute() {
    return this.$route.name === RouteNames.Statistics;
  }

  created() {
    this.registerRoutingProgressHooks();
  }

  getRegionTabItem(region: IRegion): IRegionTabItem {
    let routeToLinkTo: RouteNames;
    const fullPath = this.$route.fullPath;

    switch (true) {
      case fullPath.includes(RouteNames.WeatherPage):
        routeToLinkTo = RouteNames.WeatherPage;
        break;
      case fullPath.includes(RouteNames.DailyAssessment):
        routeToLinkTo = RouteNames.DailyAssessmentSelector;
        break;
      case fullPath.includes(RouteNames.SiteServiceHistorySlug):
        routeToLinkTo = RouteNames.SiteServiceHistory;
        break;
      case fullPath.includes(RouteNames.SiteService):
        routeToLinkTo = RouteNames.SiteServiceOverview;
        break;
      default:
        routeToLinkTo = RouteNames.IncidentServiceOverview;
    }

    return {
      id: region.id,
      name: region.name,
      to: {
        name: routeToLinkTo,
        params: { regionId: region.id }
      }
    };
  }

  refresh() {
    window.location.reload();
  }

  /**
   * Register hooks in the router to show/hide an app-wide progress bar. The progress bar is shown for all routing
   * actions and will also include API fetches as long as they are performed as part of routing.
   */
  registerRoutingProgressHooks() {
    this.$router.beforeEach((to, from, next) => {
      this.loading = true;
      try {
        next();
      } catch (e) {
        this.loading = false;
      }
    });

    this.$router.afterEach(() => {
      this.loading = false;
    });

    this.$router.onError(() => {
      this.loading = false;
    });
  }
}
