import Feature from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { Geometry, LineString, Polygon } from 'ol/geom';

export function getCenter(feature: Feature<Geometry>): Coordinate {
  const type = feature.getGeometry()?.getType();

  if (type === 'Polygon') {
    return (feature.getGeometry() as Polygon).getInteriorPoint().getCoordinates();
  }
  if (type == 'LineString') {
    return (feature.getGeometry() as LineString).getCoordinateAt(0.5);
  }
  throw new Error(`unsupported geometry '${type}'`);
}
