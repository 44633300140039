
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAttachment } from '@/model';

@Component
export default class AttachmentList extends Vue {
  @Prop(Array)
  public attachments!: IAttachment[];

  @Prop({
    type: Boolean,
    default: false
  })
  public editMode!: boolean;

  private attachmentIdToDelete!: string;

  private deleteDialog = false;

  private openDeleteDialog(id: string): void {
    this.deleteDialog = true;
    this.attachmentIdToDelete = id;
  }

  private deleteAttachment(id: string): void {
    this.deleteDialog = false;
    this.$emit('deleted', id);
  }

  private openAttachment(url: string) {
    window.open(url, '_blank');
  }
}
