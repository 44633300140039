
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { IActionPackage } from '@/model';
import { cloneDeep } from 'lodash-es';

@Component
export default class ActionPackageSelector extends Vue {
  public get unusedActionPackages(): IActionPackage[] {
    const usedActionPackagesIds = this.selectedActionPackages.map(pkg => pkg.id);
    return this.availableActionPackages.filter(pkg => !usedActionPackagesIds.includes(pkg.id));
  }

  public get hasUnusedActionPackages(): boolean {
    return this.unusedActionPackages.length > 0;
  }

  public get showActions(): boolean {
    return this.hasSelectedActionPackages || this.switchState;
  }

  public get hasSelectedActionPackages(): boolean {
    return this.selectedActionPackages.length > 0;
  }

  @Prop()
  public regionId!: string;

  @Prop()
  public actionPackages!: IActionPackage[];

  @Prop()
  public editMode!: boolean;

  @Prop()
  public availableActionPackages!: IActionPackage[];

  public displayRemoveDialog = false;
  public switchState = false;
  public selectedActionPackages: IActionPackage[] = [];

  @Watch('actionPackages', { immediate: true, deep: true })
  public watchActionPackages(actionPackagesFromProps: IActionPackage[]): void {
    this.selectedActionPackages = [...actionPackagesFromProps];

    if (this.hasSelectedActionPackages) {
      this.switchState = true;
    } else {
      this.switchState = false;
    }
  }

  public toggleActions(status: boolean) {
    if (status) {
      this.switchState = true;
    } else {
      this.switchState = false;
      this.displayRemoveDialog = true;
    }
  }

  public deleteActions() {
    this.displayRemoveDialog = false;
    this.switchState = false;
    this.selectedActionPackages = [];
    this.emitUpdate([]);
  }

  public cancelActionsDeletion() {
    this.displayRemoveDialog = false;
    this.switchState = true;
  }

  public addActionPackage(actionPackage: IActionPackage) {
    // unset all selected blasting methods when adding a package
    let newActionPackage = cloneDeep(actionPackage);
    newActionPackage.blastingMethods = [];

    this.selectedActionPackages.push(newActionPackage);
    this.emitUpdate(this.selectedActionPackages);
  }

  public removeActionPackage(packageId: string) {
    this.selectedActionPackages.splice(
      this.selectedActionPackages.findIndex(pkg => pkg.id === packageId),
      1
    );
    this.emitUpdate(this.selectedActionPackages);
  }

  public availableBlastingMethods(actionId: string): string[] {
    let pkg = this.availableActionPackages.find(p => p.id === actionId);
    return pkg === undefined ? [] : pkg.blastingMethods;
  }

  public updateBlastingMethods(packageId: string, blastingMethods: string[]): void {
    this.selectedActionPackages = this.selectedActionPackages.map(pkg => {
      if (pkg.id === packageId) {
        return {
          ...pkg,
          blastingMethods
        };
      }
      return pkg;
    });
    this.emitUpdate(this.selectedActionPackages);
  }

  @Emit('update:action-packages')
  private emitUpdate(actionPackages: IActionPackage[]): IActionPackage[] {
    return actionPackages;
  }
}
