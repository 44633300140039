
import { Vue, PropSync, Component } from 'vue-property-decorator';
import Toast from '@/components/common/Toast/Toast.vue';

@Component({
  components: {
    Toast
  }
})
export default class ConnectionError extends Vue {
  @PropSync('isVisible') syncedIsVisible!: boolean;

  created(): void {
    window.addEventListener('online', this.onOnline);
  }

  beforeDestroy(): void {
    window.removeEventListener('online', this.onOnline);
  }

  onOnline() {
    this.syncedIsVisible = false;
  }
}
