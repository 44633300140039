
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import DataPointViz from '@/components/DataPointViz.vue';
import AvalancheProblems from '@/components/avalanche-situation/AvalancheProblems.vue';
import { IAvalancheBulletin, IAvalancheProblems, ICustomAvalancheBulletin } from '@/model';
import { cloneDeep } from 'lodash-es';

@Component({
  components: { DataPointViz, AvalancheProblems }
})
export default class AvalancheSituation extends Vue {
  @Prop()
  public editMode!: boolean;

  @Prop()
  public customAvalancheBulletin!: ICustomAvalancheBulletin;

  @Prop()
  public avalancheBulletin!: IAvalancheBulletin;

  public switchState: boolean = false;
  public displayRemoveDialog: boolean = false;
  private localCustomAvalancheBulletin: ICustomAvalancheBulletin | null = null;

  private readonly emptyBulletin: ICustomAvalancheBulletin = {
    estimatedDanger: '',
    avalancheProblemsAbove: null,
    expectedDevelopment: '',
    avalancheProblemsBelow: null
  };

  /**
   * Sync local state with property which is updated via store.
   */
  @Watch('customAvalancheBulletin', { immediate: true, deep: true })
  public onCustomAvalancheBulletin(customAvalancheBulletinFromProps: ICustomAvalancheBulletin) {
    if (customAvalancheBulletinFromProps) {
      this.localCustomAvalancheBulletin = { ...customAvalancheBulletinFromProps };
    } else {
      this.localCustomAvalancheBulletin = null;
    }

    if (this.isEstimatedDangerSpecified) {
      this.switchState = true;
    } else {
      this.switchState = false;
    }
  }

  public get showDangerSelection() {
    return this.switchState;
  }

  public get estimatedDanger() {
    return this.localCustomAvalancheBulletin !== null ? this.localCustomAvalancheBulletin.estimatedDanger : '';
  }

  public set estimatedDanger(estimatedDanger: string) {
    if (this.localCustomAvalancheBulletin !== null) {
      this.localCustomAvalancheBulletin.estimatedDanger = estimatedDanger;
      this.emitUpdate(this.localCustomAvalancheBulletin);
    }
  }

  public get translatedEstimatedDanger() {
    return this.$t(`ownObservations.avalancheBulletin.dangerLevelsChoices.${this.estimatedDanger}`);
  }

  public get isEstimatedDangerSpecified(): boolean {
    return this.estimatedDanger !== '';
  }

  get expectedDangerDevelopment(): string {
    return this.localCustomAvalancheBulletin !== null ? this.localCustomAvalancheBulletin.expectedDevelopment : '';
  }

  set expectedDangerDevelopment(expectedDevelopment) {
    if (this.localCustomAvalancheBulletin !== null) {
      this.localCustomAvalancheBulletin.expectedDevelopment = expectedDevelopment;
      this.emitUpdate(this.localCustomAvalancheBulletin);
    }
  }

  public updateAvalancheProblemsAbove(avalancheProblems: IAvalancheProblems) {
    if (this.localCustomAvalancheBulletin) {
      this.localCustomAvalancheBulletin.avalancheProblemsAbove = avalancheProblems;
      this.emitUpdate(this.localCustomAvalancheBulletin);
    }
  }

  public updateAvalancheProblemsBelow(avalancheProblems: IAvalancheProblems) {
    if (this.localCustomAvalancheBulletin) {
      this.localCustomAvalancheBulletin.avalancheProblemsBelow = avalancheProblems;
      this.emitUpdate(this.localCustomAvalancheBulletin);
    }
  }

  @Emit('update:custom-avalanche-bulletin')
  public emitUpdate(customAvalancheBulletin: ICustomAvalancheBulletin | null): ICustomAvalancheBulletin | null {
    return customAvalancheBulletin;
  }

  public toggleCustomAvalancheBulletin(status: boolean) {
    if (status) {
      this.switchState = true;
      this.resetLocalBulletin();
    } else {
      this.switchState = false;
      this.displayRemoveDialog = true;
    }
  }

  public deleteCustomAvalancheBulletin() {
    this.resetLocalBulletin();
    this.displayRemoveDialog = false;
    this.switchState = false;
    this.emitUpdate(null);
  }

  public cancelCustomAvalancheBulletinDeletion() {
    this.displayRemoveDialog = false;
    this.switchState = true;
  }

  public dangerLevels() {
    return this.getChoicesFromTranslation('ownObservations.avalancheBulletin.dangerLevelsChoices');
  }

  public avalancheProblemChoicesBelow() {
    return this.getChoicesFromTranslation('ownObservations.avalancheBulletin.avalancheProblemsBelowChoices');
  }

  public avalancheProblemChoicesAbove() {
    return this.getChoicesFromTranslation('ownObservations.avalancheBulletin.avalancheProblemsAboveChoices');
  }

  public furtherDangerDevelopments() {
    return this.getChoicesFromTranslation('ownObservations.avalancheBulletin.furtherDevelopmentChoices');
  }

  private resetLocalBulletin() {
    // resetting by making a deep copy which won't change the orginal empty template
    this.localCustomAvalancheBulletin = cloneDeep(this.emptyBulletin);
  }

  private getChoicesFromTranslation(key: string) {
    return Object.entries(this.$t(key)).map(entry => ({ text: entry[1], value: entry[0] }));
  }
}
