import {
  IInspection,
  IIssue,
  ISite,
  ISiteDetail,
  ISitesIndexed,
  ISiteServiceState
} from '@/areas/SiteService/store/types';
import { GetterTree } from 'vuex';
import { IRootState } from '@/store/types';
import { InspectionKind } from '@/api/zbag-prd/inspections/types';
import { IPartialIssueFilter } from '@/areas/SiteService/components/IssuesList/types';
import { keyBy } from 'lodash-es';

const getterTree: GetterTree<ISiteServiceState, IRootState> = {
  sitesForCurrentRegion: (state: ISiteServiceState): ISite[] => {
    // sort sites by site-nr descending
    const collator = new Intl.Collator('de', { numeric: true, sensitivity: 'base' });
    return state.sites
      .slice()
      .sort((s1, s2) => collator.compare(s1.number, s2.number))
      .reverse();
  },
  sitesForCurrentRegionById: (state: ISiteServiceState): ISitesIndexed => {
    return keyBy(state.sites, site => site.id);
  },
  getSite: (state: ISiteServiceState) => (siteId: string): ISite | undefined =>
    state.sites.find(site => site.id === siteId),
  issuesForCurrentRegion: (state: ISiteServiceState): IIssue[] => {
    return state.overview.issues;
  },
  getInspectionsForOverviewByKind: (state: ISiteServiceState) => (kind: InspectionKind): IInspection[] => {
    const findPredicate = (inspection: IInspection) => inspection.kind === kind;
    const inspectionsForKind = state.overview.inspections.filter(findPredicate);
    return inspectionsForKind || [];
  },
  siteDetail: (state: ISiteServiceState): ISiteDetail | null => state.siteDetail,
  getInspectionsForDetailViewByKind: (state: ISiteServiceState) => (kind: InspectionKind): IInspection[] => {
    const findPredicate = (inspection: IInspection) => inspection.kind === kind;
    const inspectionsForKind = state.siteDetail?.inspections.filter(findPredicate);
    return inspectionsForKind || [];
  },
  issuesForSite: (state: ISiteServiceState): IIssue[] => state.siteDetail?.issues ?? [],
  issueDetail: (state: ISiteServiceState): IIssue | null => state.issueDetail,
  currentIssueFilter: (state: ISiteServiceState): IPartialIssueFilter => state.issueFilter,
  inspectionsForSelectedDate: (state: ISiteServiceState): IInspection[] => state.inspectionsForSelectedDate,
  selectedDateForHistoricalInspections: (state: ISiteServiceState): string | null =>
    state.selectedDateForHistoricalInspections
};

export default getterTree;
