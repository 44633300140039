import { Module } from 'vuex';
import { IRootState } from '@/store/types';
import { IStatisticsState } from '@/areas/Statistics/store/types';
import { incidentsClient } from '@/api/zbag-prd/httpClients';

const statisticsModule: Module<IStatisticsState, IRootState> = {
  namespaced: true,
  state: {},
  actions: {
    downloadAllIncidents(): Promise<void> {
      return incidentsClient.exportIncidents();
    },
    downloadAllIncidentsByRescuer(): Promise<void> {
      return incidentsClient.exportIncidentsByRescuer();
    },
    downloadAccountingReport({}, monthAndYear: string): Promise<void> {
      return incidentsClient.exportAccountingReport(monthAndYear);
    }
  }
};

export default statisticsModule;
