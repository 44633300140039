
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { format } from '@/plugins/format';
import DateTimePicker from '@/components/common/DateTimePicker/DateTimePicker.vue';

@Component({
  components: { DateTimePicker }
})
export default class NextPlannedAssessmentPicker extends Vue {
  @Prop()
  public editMode!: boolean;

  @Prop({
    default() {
      return NextPlannedAssessmentPicker.getProposedDate();
    }
  })
  public nextDateProp!: Date | null;

  @Prop()
  public minDate!: Date;

  @Emit('update:next-planned-assessment-date')
  public emitNextPlannedAssessment(nextDate: Date | null): void {}

  format = format;
  proposalAdded = false;

  get nextPlannedAssessmentDate(): Date {
    return this.nextDateProp || NextPlannedAssessmentPicker.getProposedDate();
  }

  set nextPlannedAssessmentDate(date: Date) {
    this.emitNextPlannedAssessment(date);
  }

  setProposal() {
    this.emitNextPlannedAssessment(NextPlannedAssessmentPicker.getProposedDate());
    this.proposalAdded = true;
  }

  static getProposedDate() {
    const proposedDate = new Date();

    // always propose 07:00 as next time
    proposedDate.setHours(7);
    proposedDate.setMinutes(0);

    return proposedDate;
  }
}
