import BaseApiClient from '@/api/utils/BaseApiClient';
import { IDataPoint } from '@/model';

interface DataPoint {
  stationId: string;
  isPrimary: boolean;
}

export interface SnowDataPointDto extends DataPoint {
  freshSnow24H: IDataPoint[];
  freshSnow72H: IDataPoint[];
  snowDepth: IDataPoint[];
}

export interface WindDataPointDto extends DataPoint {
  windAverage3H: IDataPoint[];
  windAverage10Min: IDataPoint[];
  windPeak: IDataPoint[];
  windDirection: IDataPoint[];
}

export interface TemperatureDataPointDto extends DataPoint {
  airTemperature: IDataPoint[];
  airHumidity: IDataPoint[];
  snowSurfaceTemperature: IDataPoint[];
  snowPackTemperature: IDataPoint[];
}

export interface WeatherResponse {
  snow: {
    dataPoints: SnowDataPointDto[];
  };
  wind: {
    dataPoints: WindDataPointDto[];
  };
  temperature: {
    dataPoints: TemperatureDataPointDto[];
  };
}

export default class WeatherApiClient extends BaseApiClient {
  public async fetchWeather(regionId: string): Promise<WeatherResponse> {
    return (
      (await this.fetch(`${this.baseUrl}/weather/${regionId}`, this.requestOptions('GET'))) ?? {
        snow: { dataPoints: [] },
        wind: { dataPoints: [] },
        temperature: { dataPoints: [] }
      }
    );
  }
}
