export enum RouteNames {
  Home = 'home',
  OidcCallback = 'oidcCallback',
  UserLoadedRoot = 'user-loaded-root',
  WeatherRoot = 'weather-root',
  WeatherPage = 'weather',
  DailyAssessmentRoot = 'daily-assessment-root',
  DailyAssessmentSelector = 'daily-assessment-selector',
  DailyAssessment = 'daily-assessment',
  DailyAssessmentConditions = 'daily-assessment-conditions',
  DailyAssessmentForecast = 'daily-assessment-forecast',
  DailyAssessmentCourseOfAction = 'daily-assessment-course-of-action',
  DailyAssessmentChangelog = 'daily-assessment-changelog',
  SiteService = 'slope-service',
  SiteServiceRoot = 'site-service-root',
  SiteServiceAddFinalInspection = 'site-service-add-final-inspection',
  SiteServiceAddTechnicalInspection = 'site-service-add-technical-inspection',
  SiteServiceOverview = 'site-service-overview',
  SiteServiceAddIssue = 'site-service-add-issue',
  SiteServiceSite = 'site-service-site',
  SiteServiceInspection = 'site-service-inspection',
  SiteServiceIssue = 'site-service-issue',
  SiteServiceHistory = 'site-service-history',
  SiteServiceHistorySlug = 'history',
  IncidentCollisionReport = 'incidents-collision-report',
  IncidentService = 'rescue-service',
  IncidentServiceOverview = 'incidents-overview',
  IncidentServiceAddIncident = 'incidents-add-incident',
  IncidentServiceIncident = 'incidents-incident',
  IncidentServiceRoot = 'incidents-root',
  Statistics = 'statistics'
}

// To facilitate redirection to the user's default region, we need to use some non-empty value as stand-in
// for the default region ID, because the default region ID is not known at the time of the redirect.
export const defaultRegionIdPlaceholder = '_';
