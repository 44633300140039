
import { Component, Vue } from 'vue-property-decorator';
import { isNavigationFailure } from 'vue-router';
import { Action } from 'vuex-class';

@Component
export default class OidcCallback extends Vue {
  @Action('oidcSignInCallback', { namespace: 'oidc' })
  public oidcSignInCallback!: () => Promise<string>;

  private isLoading = true;
  private hasRedirectFailed = false;

  public mounted() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath).catch(e => {
          if (!isNavigationFailure(e)) {
            // handle error if it's an unexpected one.
            // navigation errors are not actual errors, since they are usually thrown when a navigation is cancelled due to a redirect.
            // https://router.vuejs.org/api/enums/NavigationFailureType.html
            this.isLoading = false;
            this.hasRedirectFailed = true;
          }
        });
      })
      .catch(err => {
        /* eslint-disable-next-line no-console */
        console.error(err);
      });
  }
}
